<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="10">
      <template v-slot:activator="{}">
        <v-select
          v-if="!hledani"
          ref="select"
          v-bind="computedProps"
          v-on="$listeners"
          :style="stylesObject"
          class="lowerColorOpacity"
          :hide-details="componentIsFilter"
        ></v-select>
        <v-autocomplete
          v-else
          ref="select"
          v-bind="computedProps"
          v-on="$listeners"
          :style="stylesObject"
          class="lowerColorOpacity"
          :hide-details="componentIsFilter"
        ></v-autocomplete>
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VSelect } from 'vuetify/lib';
import store from '../../store/index';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VSelect,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    pridatNezvoleno: {
      type: Boolean,
      default: false
    },
    pridatVse: {
      type: Boolean,
      default: false
    },
    jeStavemZaznamu: {
      type: Boolean,
      default: false
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    zvolitPrvni: {
      type: Boolean,
      default: true
    },
    stylesObject: {
      type: Object,
      default: null
    },
    hledani: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedItems() {
      const items = [];
      if (this.jeStavemZaznamu) {
        store.state.itemStates.forEach(el => {
          items.push({
            key: el.id,
            value: el.description
          });
        });
      }
      if (this.pridatNezvoleno) {
        items.unshift({ [this.itemValue]: null, [this.itemText]: 'Nezvoleno' });
      }
      if (this.pridatVse) {
        items.unshift({ [this.itemValue]: null, [this.itemText]: 'Vše' });
      }
      return [...items, ...this.items];
    },
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace && props.clearable;
      props.items = this.computedItems;
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-arrow-down-drop-circle-outline'
          : props.prependIcon;
      props.rules = [];
      if (this.jePovinne) {
        if (props.multiple) {
          props.rules.push(
            value => (value && value.length != 0) || 'Pole je povinné'
          );
        } else {
          props.rules.push(value => !!value || 'Pole je povinné');
        }
      }
      return props;
    }
  },
  beforeCreate() {
    this.constructor.options.props.itemText.default = () => 'value';
    this.constructor.options.props.itemValue.default = () => 'key';
  },
  beforeMount() {
    if (this.jeStavemZaznamu) {
      this.selectFirstItem();
    }
  },
  methods: {
    selectFirstItem() {
      if (this.zvolitPrvni && !this.value && this.computedItems.length !== 0) {
        if (this.returnObject) {
          this.selectItem(this.computedItems[0]);
        } else {
          if (this.jeStavemZaznamu) {
            this.selectItem(
              this.computedItems.find(f => f.key == 1)[this.itemValue]
            );
          } else {
            this.selectItem(this.computedItems[0][this.itemValue]);
          }
        }
      }
    },
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.select?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.select.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  },
  watch: {
    computedItems: {
      immediate: true,
      handler() {
        this.selectFirstItem();
      }
    }
  }
};
</script>

<style lang="scss">
.theme--dark {
  .v-select__slot {
    .v-select__selection--disabled {
      color: #ffffff;
    }
  }
}

.theme--light {
  .v-select__slot {
    .v-select__selection--disabled {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
</style>
