/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  priceListsRegistry: [],
  priceListRegistry: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getPriceListsRegistry({ commit, rootState }, filterOptions) {
    try {
      const { data } = await axios.get('priceListRegistry/get', {
        params: filterOptions
      });
      const filteredData = filterOrderGetPage(data, filterOptions ?? null);
      commit('priceListsRegistry', filteredData);
    } catch (e) {
      commit(
        'priceListsRegistry',
        rootState.offlineRegistry.priceListsRegistry
      );
      // error handling is in interceptor
    }
  },
  async getPriceListRegistryById({ commit }, id) {
    try {
      const { data } = await axios.get(`priceListRegistry/get/${id}`);

      commit('priceListRegistry', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createPriceListRegistry({}, createPriceListRegistryModel) {
    try {
      await axios.post(
        `priceListRegistry/create`,
        createPriceListRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updatePriceListRegistry({}, updatePriceListRegistryModel) {
    try {
      await axios.put(`priceListRegistry/update`, updatePriceListRegistryModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deletePriceListRegistry({}, id) {
    try {
      await axios.delete(`priceListRegistry/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
