<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card>
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <InputText
              label="Uživatelské jméno"
              v-model="user.userName"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="formMode == formModes.new">
            <InputText
              label="Heslo"
              v-model="user.password"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            /> </v-col
          ><v-col cols="12" class="py-0">
            <InputText
              label="Jméno"
              v-model="user.firstname"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputText
              label="Příjmení"
              v-model="user.lastname"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputText
              label="Email"
              v-model="user.email"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputText
              label="Telefon"
              v-model="user.phoneNumber"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Start číselné řady"
              v-model.number="user.numeralStart"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <Select
              label="Role"
              :items="roles"
              item-value="id"
              item-text="name"
              jePovinne
              v-model="selectedRoles"
              :povolenaEditace="formMode != formModes.read"
              multiple
              :zvolitPrvni="false"
              :menu-props="{ top: true, offsetY: true }"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <Checkbox
              label="Povoleno"
              v-model="user.enabled"
              :povolenaEditace="formMode != formModes.read"/></v-col></v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  UserRolesAddModel,
  UserRolesDeleteModel
} from '../store/models/userRolesModels';
import { UserCreateModel, UserUpdateModel } from '../store/models/userModels';
import { getDifferences } from '../utils/DifferenceHelper';

export default {
  data() {
    return {
      formMode: null,
      formValid: true,
      selectedRoles: null,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getUserById(this.idProp);
      await this.verifySuccessCall();
      await this.verifySuccessCall();

      this.selectedRoles = this.user.roles.map(el => el.id);
    } else {
      this.user.enabled = true;
    }
    await this.getRoles();
    await this.verifySuccessCall();
    this.original = this.$_.cloneDeep(this.user);
  },
  computed: {
    ...window.get(['formModes', 'role/roles', 'userRole/userRoles']),
    ...window.sync(['user/user']),
    formTitle() {
      return `Uživatel - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'role/getRoles',
      'user/getUserById',
      'user/createUser',
      'user/updateUser',
      'userRole/addUserRole',
      'userRole/deleteUserRole'
    ]),
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.user, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.user = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }

      if (this.formMode == this.formModes.new) {
        // tady pridam uzivateli vsechny znovelene role
        await this.createUser(
          new UserCreateModel(
            this.user.userName,
            this.user.firstname,
            this.user.lastname,
            this.user.email,
            this.user.phoneNumber,
            this.user.enabled,
            this.user.numeralStart,
            this.user.password
          )
        );
        if (!(await this.verifySuccessCall())) return;

        this.selectedRoles.forEach(
          async el =>
            await this.addUserRole(
              new UserRolesAddModel(this.user.id.toString(), el.toString())
            )
        );

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Uživatel byl vytvořen.');
          await this.close(true);
        }
      } else {
        // tady zkontroluju jestli uzivatel nejakou roli nevyhodil a smazu, potom pridam nove role
        await this.updateUser(
          new UserUpdateModel(
            this.user.id,
            this.user.userName,
            this.user.firstname,
            this.user.lastname,
            this.user.email,
            this.user.phoneNumber,
            this.user.numeralStart,
            this.user.enabled
          )
        );
        if (!(await this.verifySuccessCall())) return;

        const { toDelete: rolesToDelete, toAdd: rolesToAdd } = getDifferences(
          this.original.roles,
          this.selectedRoles
        );

        rolesToDelete.forEach(
          async el =>
            await this.deleteUserRole(
              new UserRolesDeleteModel(this.user.id.toString(), el.toString())
            )
        );

        rolesToAdd.forEach(
          async el =>
            await this.addUserRole(
              new UserRolesAddModel(this.user.id.toString(), el.toString())
            )
        );

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Uživatel byl upraven.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
