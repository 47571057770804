export default {
  methods: {
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 48 && // topbar height
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }
};
