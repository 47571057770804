import VuexPersist from 'vuex-persist';

const vuexLocal = new VuexPersist({
  key: 'FC_StoreLocal',
  storage: window.localStorage,
  reducer: state => ({
    offlineRegistry: state.offlineRegistry,
    auth: state.auth
  })
});

export default vuexLocal;
