<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <!-- <v-col cols="12" class="py-0"
            ><InputText
              label="Identifikátor"
              v-model="growingActivityRegistry.identificator"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col> -->
          <v-col cols="12" class="py-0"
            ><InputText
              label="Název"
              v-model="growingActivityRegistry.name"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Jednotková cena"
              v-model="growingActivityRegistry.unitPrice"
              jePovinne
              :min="-2147483647"
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputText
              label="Jednotka"
              v-model="growingActivityRegistry.unit"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <CisBox
              label="Nadřazená položka"
              formular="Registry/GrowingActivitiesRegistry"
              valueLabel="name"
              width="60rem"
              v-model="growingActivityRegistry.parent"
              :povolenaEditace="formMode != formModes.read"
            />
            <Checkbox
              label="Skladem"
              v-model="growingActivityRegistry.inStock"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost od"
              v-model="growingActivityRegistry.dateFrom"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost do"
              v-model="growingActivityRegistry.dateTo"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            /> </v-col></v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  GrowingActivityRegistryCreateModel,
  GrowingActivityRegistryUpdateModel
} from '../../store/models/growingActivityRegistryModels';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getGrowingActivityRegistryById(this.idProp);
      await this.verifySuccessCall();
    }
    this.original = this.$_.cloneDeep(this.growingActivityRegistry);
  },
  computed: {
    ...window.get(['formModes']),
    ...window.sync(['growingActivityRegistry/growingActivityRegistry']),
    formTitle() {
      return `Pěstební činnost - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'growingActivityRegistry/getGrowingActivityRegistryById',
      'growingActivityRegistry/createGrowingActivityRegistry',
      'growingActivityRegistry/updateGrowingActivityRegistry'
    ]),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.growingActivityRegistry, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.growingActivityRegistry = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createGrowingActivityRegistry(
          new GrowingActivityRegistryCreateModel(
            this.growingActivityRegistry.name,
            this.growingActivityRegistry.name,
            this.growingActivityRegistry.unitPrice,
            this.growingActivityRegistry.inStock,
            this.growingActivityRegistry.dateFrom,
            this.growingActivityRegistry.dateTo,
            this.growingActivityRegistry.parent?.id,
            this.growingActivityRegistry.unit
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Pěstební činnost byla vytvořena.');
          await this.close(true);
        }
      } else {
        await this.updateGrowingActivityRegistry(
          new GrowingActivityRegistryUpdateModel(
            this.growingActivityRegistry.name,
            this.growingActivityRegistry.name,
            this.growingActivityRegistry.unitPrice,
            this.growingActivityRegistry.inStock,
            this.growingActivityRegistry.dateFrom,
            this.growingActivityRegistry.dateTo,
            this.growingActivityRegistry.parent?.id,
            this.growingActivityRegistry.unit,
            this.growingActivityRegistry.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Pěstební činnost byla upravena.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
