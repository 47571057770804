class AdditionalCostsRegistryCreateModel {
  name: string;
  unitPrice: number;
  dateFrom: Date;
  dateTo: Date;
  constructor(name: string, unitPrice: number, dateFrom: Date, dateTo: Date) {
    this.name = name;
    this.unitPrice = unitPrice;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}

class AdditionalCostsRegistryUpdateModel {
  name: string;
  unitPrice: number;
  dateFrom: Date;
  dateTo: Date;
  id: number;
  constructor(
    name: string,
    unitPrice: number,
    dateFrom: Date,
    dateTo: Date,
    id: number
  ) {
    this.name = name;
    this.unitPrice = unitPrice;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.id = id;
  }
}

export {
  AdditionalCostsRegistryCreateModel,
  AdditionalCostsRegistryUpdateModel
};
