<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-card style="height:100%;">
            <v-container style="height:100%;">
              <v-row dense>
                <v-col cols="12" class="py-0"
                  ><DatePicker
                    label="Datum"
                    v-model="growingActivity.date"
                    jePovinne
                    :clearable="false"
                    :povolenaEditace="formMode != formModes.read"/></v-col
              ></v-row>
              <v-row dense>
                <v-col cols="12" class="py-0"
                  ><Select
                    label="Zákazník"
                    v-model="growingActivity.customerId"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    jePovinne
                    hledani
                    :zvolitPrvni="false"
                    :povolenaEditace="formMode != formModes.read"/></v-col
              ></v-row>
              <v-row dense>
                <v-col cols="12" class="py-0"
                  ><Textarea
                    label="Poznámka"
                    rows="3"
                    v-model="growingActivity.note"
                    :povolenaEditace="formMode != formModes.read"/></v-col
              ></v-row>
              <v-row dense style="height:100%;">
                <v-col cols="12">
                  <v-toolbar dense class="v-toolbar-scrollable">
                    <Button
                      tooltip="Založení nového záznamu"
                      @click="showDialogActivityRecordDetail(formModes.new)"
                      ><v-icon>mdi-plus</v-icon></Button
                    >
                    <Button
                      tooltip="Zobrazení detailu záznamu"
                      @click="showDialogActivityRecordDetail(formModes.read)"
                      :povolenaEditace="!!selectedRowGrowingActivityRecords"
                      ><v-icon>mdi-file-outline</v-icon></Button
                    >
                    <Button
                      tooltip="Změna údajů záznamu"
                      @click="showDialogActivityRecordDetail(formModes.edit)"
                      :povolenaEditace="!!selectedRowGrowingActivityRecords"
                      ><v-icon>mdi-pencil</v-icon></Button
                    >
                    <Button
                      tooltip="Zrušení (zneaktivnění) záznamu"
                      @click="deleteRowGrowingActivityRecordDetail"
                      :povolenaEditace="
                        !!selectedRowGrowingActivityRecords &&
                          formMode != formModes.read
                      "
                      ><v-icon>mdi-delete-outline</v-icon></Button
                    >
                    <v-divider vertical class="mx-2"></v-divider>
                    <Button tooltip="Zobrazí nápovědu" :povolenaEditace="false"
                      ><v-icon>mdi-help-circle-outline</v-icon></Button
                    >
                    <Button
                      tooltip="Zobrazí tiskovou sestavu"
                      :povolenaEditace="false"
                      ><v-icon>mdi-printer</v-icon></Button
                    >
                  </v-toolbar></v-col
                >
                <v-col cols="12" style="height: calc(100% - 56px)">
                  <DataTable
                    :headers="headersGrowingActivityRecords"
                    :items="growingActivity.growingActivities"
                    item-key="id"
                    :selectedItem.sync="selectedRowGrowingActivityRecords"
                    @dblclick:row="
                      showDialogActivityRecordDetail(formModes.read)
                    "
                    :options.sync="optionsGrowingActivityRecords"
                    :server-items-length="totalRecordsGrowingActivityRecords"
                    single-select
                    :hide-default-footer="true"
                    @contextmenu:row="showContextMenuActivityRecordDetail"
                    height="100%"
                  >
                    <template
                      v-slot:[`item.growingActivityRegistry.unitPrice`]="{
                        item
                      }"
                    >
                      <i v-if="item.customPrice != null">{{
                        item.customPrice
                      }}</i>
                      <span v-else>{{
                        item.growingActivityRegistry.unitPrice
                      }}</span>
                    </template>
                  </DataTable>
                  <v-menu
                    v-model="contextMenuGrowingActivityRecordsVisible"
                    :disabled="!selectedRowGrowingActivityRecords"
                    :position-x="rightClickPositionX"
                    :position-y="rightClickPositiony"
                    absolute
                    offset-y
                  >
                    <v-list dense>
                      <v-list-item
                        @click="showDialogActivityRecordDetail(formModes.read)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-file-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Detail</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="showDialogActivityRecordDetail(formModes.edit)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Editace</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="deleteRowGrowingActivityRecordDetail"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Zrušit</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu></v-col
                ></v-row
              >
            </v-container>
          </v-card>
        </v-row></v-container
      >
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  GrowingActivityCreateModel,
  GrowingActivityUpdateModel,
  GrowingActivityRecordCreateModel,
  GrowingActivityRecordUpdateModel
} from '../store/models/growingActivityModels';
import growingActivityRecordDetail from './GrowingActivityRecordDetail.vue';
import dataTableContextMenuMixin from '../mixins/datatableContextMenuMixin';
import { getDifferences } from '../utils/DifferenceHelper';

export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null,
      tab: null,
      headersGrowingActivityRecords: [
        { text: 'Název', value: 'detailName', width: '65%' },
        { text: 'Množství', value: 'totalAmmount' },
        {
          text: 'Cena jednotková',
          value: 'growingActivityRegistry.unitPrice'
        }
      ],
      optionsGrowingActivityRecords: {},
      selectedRowGrowingActivityRecords: null,
      totalRecordsGrowingActivityRecords: 0,
      contextMenuGrowingActivityRecordsVisible: false
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  mounted() {
    this.$watch(
      () => this.growingActivity.growingActivities,
      () => {
        this.sumPrice();
      }
    );
  },
  async created() {
    this.formMode = this.formModeProp;

    await this.getCompanies();
    await this.verifySuccessCall();

    await this.getGrowingActivitiesRegistry({
      ...this.filters,
      ...this.options,
      enablePaging: false,
      date: this.filterDate
    });

    if (this.idProp) {
      await this.getGrowingActivityById(this.idProp);
      await this.verifySuccessCall();

      this.getDetailNames();
      this.totalRecordsGrowingActivityRecords = this.growingActivity.growingActivities.length;
    } else {
      this.$set(this.growingActivity, 'date', this.$moment());
    }

    this.$refs.form?.resetValidation();
    this.original = this.$_.cloneDeep(this.growingActivity);
  },
  computed: {
    ...window.get([
      'formModes',
      'company/companies',
      'car/cars',
      'growingActivityRegistry/growingActivitiesRegistry'
    ]),
    ...window.sync(['growingActivity/growingActivity']),

    formTitle() {
      return `Pěstební činnost - ${this.formMode}`;
    }
  },
  methods: {
    getDetailNames() {
      this.growingActivity.growingActivities = this.growingActivity.growingActivities.map(
        el => ({
          ...el,
          detailName: `${this.getParentName(el.growingActivityRegistry)} > ${
            el.growingActivityRegistry.name
          }`
        })
      );
    },
    ...window.call([
      'company/getCompanies',
      'growingActivity/getGrowingActivityById',
      'growingActivity/createGrowingActivity',
      'growingActivity/updateGrowingActivity',
      'growingActivityRecord/deleteGrowingActivityRecord',
      'growingActivityRegistry/getGrowingActivitiesRegistry'
    ]),
    sumPrice() {
      this.growingActivity.totalPrice = 0;
      this.growingActivity.growingActivities.forEach(ga => {
        this.growingActivity.totalPrice +=
          (ga?.customPrice ?? ga.growingActivityRegistry.unitPrice) *
          ga.totalAmmount;
      });
    },
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.growingActivity, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.growingActivity = { growingActivities: [] };
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createGrowingActivity(
          new GrowingActivityCreateModel(
            this.growingActivity.growingActivities.map(
              el =>
                new GrowingActivityRecordCreateModel(
                  el.growingActivityRegistry.id,
                  el.totalAmmount,
                  el.customPrice
                )
            ),
            this.growingActivity.customerId,
            this.growingActivity.date,
            this.growingActivity.note,
            this.growingActivity.totalPrice
          )
        );

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Pěstební činnost byla vytvořena.');
          await this.close(true);
        }
      } else {
        await this.updateGrowingActivity(
          new GrowingActivityUpdateModel(
            this.growingActivity.growingActivities.map(
              el =>
                new GrowingActivityRecordUpdateModel(
                  el.growingActivityRegistry.id,
                  el.totalAmmount,
                  el.customPrice,
                  el.id
                )
            ),
            this.growingActivity.customerId,
            this.growingActivity.date,
            this.growingActivity.note,
            this.growingActivity.totalPrice,
            this.growingActivity.id
          )
        );

        const { toDelete: growingActivityRecordToDelete } = getDifferences(
          this.original.growingActivities,
          this.growingActivity.growingActivities
        );
        growingActivityRecordToDelete.forEach(
          async el => await this.deleteGrowingActivityRecord(el)
        );

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Pěstební činnost byla upravena.');
          await this.close(true);
        }
      }
    },
    async showDialogActivityRecordDetail(formMode) {
      const dialogResult = await this.$dialog.showAndWait(
        growingActivityRecordDetail,
        {
          width: '30rem',
          persistent: true,
          formModeProp: formMode,
          growingActivityRecordProp:
            formMode != this.formModes.new
              ? { ...this.selectedRowGrowingActivityRecords }
              : {
                  id:
                    this.growingActivity.growingActivities.length != 0
                      ? this.$_.min([
                          this.$_.minBy(
                            this.growingActivity.growingActivities,
                            'id'
                          ).id - 1,
                          0
                        ])
                      : 0
                },
          filterDate: this.$moment(this.growingActivity.date)
            .utc(true)
            .format()
        }
      );

      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.growingActivity.growingActivities.push(
            dialogResult.newGrowingActivityRecord
          );
          this.totalRecordsGrowingActivityRecords += 1;
          this.$dialog.notify.info('Záznam pěstební činnosti byl přidán.');
        } else {
          const index = this.growingActivity.growingActivities.indexOf(
            this.growingActivity.growingActivities.find(
              el => el.id == dialogResult.newGrowingActivityRecord.id
            )
          );
          this.growingActivity.growingActivities.splice(index, 1);
          this.growingActivity.growingActivities.splice(
            index,
            0,
            dialogResult.newGrowingActivityRecord
          );
          this.$dialog.notify.info('Záznam pěstební činnosti byl upraven.');
        }
        this.getDetailNames();
      }
    },
    async deleteRowGrowingActivityRecordDetail() {
      this.growingActivity.growingActivities = this.growingActivity.growingActivities.filter(
        el => el.id != this.selectedRowGrowingActivityRecords.id
      );
      this.totalRecordsGrowingActivityRecords -= 1;
      this.$dialog.notify.info('Záznam pěstební činnosti byl odstraněn.');
    },
    showContextMenuActivityRecordDetail(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuGrowingActivityRecordsVisible = true;
      });
    },
    getParentName(growingActivity) {
      const parents = [];

      let item = this.growingActivitiesRegistry.find(
        el => el.id == growingActivity.parentId
      );

      while (item) {
        parents.push(item.name);
        item = this.growingActivitiesRegistry.find(
          el => el.id == item.parentId
        );
      }
      return parents.reverse().join(' > ');
    }
  }
};
</script>

<style></style>
