class UserCreateModel {
  userName: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  enabled: boolean;
  numeralStart: number;
  password: string;
  constructor(
    userName: string,
    firstname: string,
    lastname: string,
    email: string,
    phoneNumber: string,
    enabled: boolean,
    numeralStart: number,
    password: string
  ) {
    this.userName = userName;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.numeralStart = numeralStart;
    this.password = password;
  }
}

class UserUpdateModel {
  id: number;
  userName: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  numeralStart: number;
  enabled: boolean;
  constructor(
    id: number,
    userName: string,
    firstname: string,
    lastname: string,
    email: string,
    phoneNumber: string,
    numeralStart: number,
    enabled: boolean
  ) {
    this.id = id;
    this.userName = userName;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.numeralStart = numeralStart;
    this.enabled = enabled;
  }
}

export { UserCreateModel, UserUpdateModel };
