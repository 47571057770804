import Vue from 'vue';

export default Vue.mixin({
  methods: {
    formatPrice(price) {
      const formattedPrice = price
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$& ');
      return formattedPrice + ' Kč';
    },
    round2Decimals(number) {
      return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
    }
  }
});
