/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
var qs = require('qs');

const state = {};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getAccountingData({}, model) {
    try {
      return await axios.post('accounting/show', model);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getAccountingPdf({}, model) {
    try {
      return await axios.post(`accounting/generate`, model);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
