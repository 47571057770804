/**
 * Vytvori uzly stromu
 *
 * @param {object}    [rootObj]     struktura rootove/nadrizene polozky - striktni poradi props [0 = nadrizene ID, 1 = ID]
 * @param {array}     [arr]         pole libovolnych objektu
 * @param {bool}      [done=false]  priznak o zpracovani objektu do uzlu stromu
 */
function createNodes(rootObj, arr, done = false) {
  const result = { data: [], missingData: [] };
  arr.forEach(el => {
    const rootPropName = Object.keys(rootObj)[0];
    const rootIdValue = el[rootPropName];
    const propName = Object.keys(rootObj)[1];

    if ((rootIdValue ?? undefined) === rootObj[rootPropName]) {
      const obj = {};
      obj[rootPropName] = el[propName];
      obj[propName] = undefined;
      el.children = createNodes(obj, arr, done).data;
      el.children.forEach(ch => (ch.parent = el));
      result.data.push(el);
    } else if (
      rootIdValue &&
      (!arr.some(item => item[propName] === rootIdValue) ||
        result.missingData.some(item => item[propName] === rootIdValue))
    ) {
      if (done === false && !result.missingData.includes(el)) {
        result.missingData.push(el);
      }
    }
  });
  return result;
}

/**
 * Vytvori uzly stromu bez nadrizeneho uzlu
 *
 * @param {object}    [obj]           struktura rootove/nadrizene polozky - striktni poradi props [0 = nadrizene ID, 1 = ID]
 * @param {array}     [missingData]   pole objektu bez nadrizeneho uzlu stromu pro nasledne zpracovani
 */
function createMissingNodes(obj, missingData) {
  const result = [];
  missingData.forEach(el => {
    if (
      !missingData.some(
        item => item[Object.keys(obj)[1]] === el[Object.keys(obj)[0]]
      )
    ) {
      obj[Object.keys(obj)[0]] = el[Object.keys(obj)[1]];

      el.children = createNodes(obj, missingData, true).data;
      result.push(el);
    }
  });

  return result;
}

/**
 * Transformuje objekt uzlu stromu pro prezentacni podobu PrimeVue.TreeTable
 *
 * @param     {object}           [item]     objekt pro transformaci dat
 * @param     {number/string}    [index]    poradi objektu v poli vuci uzlu stromu
 *
 * @returns   {object} TreeNode object as its 'value' and columns defined with Column component.
 * TreeNode API utilized by the TreeTable
 * Name       Type        Default   Description
 * key        any         null      Mandatory unique key of the node.
 * data       any         null      Data represented by the node.
 * children   TreeNode[]  null      An array of treenodes as children.
 * style      string      null      Inline style of the node.
 * styleClass string      null      Style class of the node.
 * leaf       boolean     null      Specifies if the node has children. Used in lazy loading.
 */
function createTreeTableItem(item, index) {
  return {
    key: `${index}`,
    data: item,
    children: item.children?.map((polozka, i) => {
      return createTreeTableItem(polozka, `${index}-${i}`);
    })
  };
}

/**
 * Vrati data pro PrimeVue.TreeTable s podrizenymi polozkami children
 *
 * @param {object}    [rootObj]     struktura rootove/nadrizene polozky - striktni poradi props [0 = nadrizene ID, 1 = ID]
 * @param {array}     [arr]         pole libovolnych objektu
 */
function getTreeTableData(rootObj, arr) {
  // vytvori uzly stromu
  const nodes = createNodes(rootObj, arr);
  // vytvori vsechny uzly stromu vcetne podrizenych uzlu, ktere nemaji nadrizeny uzel
  const allNodes = nodes.data.concat(
    createMissingNodes(rootObj, nodes.missingData)
  );
  // // transformuje uzly stromu do prezencni podoby PrimeVue.TreeTable
  // const treeTableData = [];
  // allNodes.forEach((item, index) => {
  //   treeTableData.push(createTreeTableItem(item, index));
  // });
  // return treeTableData;
  return allNodes;
}

export { getTreeTableData, createTreeTableItem };
