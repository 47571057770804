<template>
  <v-container>
    <v-card>
      <v-container>
        <v-list>
          <v-list-item
            ><v-switch
              :value="colorMode"
              @change="setApplicationColorScheme"
              label="Tmavé schéma"
            ></v-switch
          ></v-list-item>
          <!-- <v-list-item>
            <v-switch
              :value="layoutMode"
              @change="switchLayoutMode"
              label="Překrývací menu"
            ></v-switch>
          </v-list-item> -->
          <!-- <v-list-item>
            <Select
              :value="rowsInTable"
              :items="availableRowsInTable"
              @change="rowsInTableChange"
              label="Počet řádků v tabulce"
            ></Select>
          </v-list-item> -->
          <v-list-item>
            <Button @click="updateOfflineRegistry"
              >Aktualizovat offline číselníky</Button
            >
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      colorMode: false,
      layoutMode: false,
      availableRowsInTable: [10, 20, 30, 50, 100]
    };
  },
  name: 'UserPreferences',
  computed: {
    ...window.get([
      'userConfig/getColorMode',
      'userConfig/getLayoutMode',
      'userConfig/rowsInTable'
    ])
  },
  methods: {
    ...window.call([
      'userConfig/switchColorMode',
      'userConfig/switchLayoutMode',
      'userConfig/setRowsInTable',
      'offlineRegistry/getData'
    ]),
    setApplicationColorScheme() {
      this.switchColorMode();
      this.$vuetify.theme.dark = this.getColorMode == 'dark';
    },
    rowsInTableChange(value) {
      this.setRowsInTable(value);
    },
    async updateOfflineRegistry() {
      await this.getData();
      this.$dialog.notify.info(
        'Číselníky pro offline režim byly aktualizovány.'
      );
    }
  },
  mounted() {
    this.colorMode = this.getColorMode == 'dark';
    this.layoutMode = this.getLayoutMode == 'overlay';
  }
};
</script>

<style></style>
