class AccountingModel {
  companyId: number;
  from: Date;
  to: Date;
  selling: boolean;
  includeGrowingActivity: boolean;
  margin: number;
  additionalCostRegistrys: Array<AdditionalCostRegistryLocalityModel>;
  localities: Int32Array;
  constructor(
    companyId: number,
    from: Date,
    to: Date,
    selling: boolean,
    includeGrowingActivity: boolean,
    margin: number,
    additionalCostRegistrys: Array<AdditionalCostRegistryLocalityModel>,
    localities: Int32Array
  ) {
    this.companyId = companyId;
    this.from = from;
    this.to = to;
    this.selling = selling;
    this.includeGrowingActivity = includeGrowingActivity;
    this.margin = margin;
    this.additionalCostRegistrys = additionalCostRegistrys;
    this.localities = localities;
  }
}

class AdditionalCostRegistryLocalityModel {
  LocalityId: number;
  AdditionalCostRegistryId: number;
  constructor(LocalityId: number, AdditionalCostRegistryId: number) {
    this.LocalityId = LocalityId;
    this.AdditionalCostRegistryId = AdditionalCostRegistryId;
  }
}

export { AccountingModel, AdditionalCostRegistryLocalityModel };
