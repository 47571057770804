class GrowingActivityCreateModel {
  growingActivityRecord: Array<GrowingActivityRecordCreateModel>;
  customerId: number;
  date: Date;
  note: string;
  totalPrice: number;
  constructor(
    growingActivityRecord: Array<GrowingActivityRecordCreateModel>,
    customerId: number,
    date: Date,
    note: string,
    totalPrice: number
  ) {
    this.growingActivityRecord = growingActivityRecord;
    this.customerId = customerId;
    this.date = date;
    this.note = note;
    this.totalPrice = totalPrice;
  }
}

class GrowingActivityUpdateModel {
  growingActivityRecord: Array<GrowingActivityRecordCreateModel>;
  customerId: number;
  date: Date;
  note: string;
  totalPrice: number;
  id: number;
  constructor(
    growingActivityRecord: Array<GrowingActivityRecordCreateModel>,
    customerId: number,
    date: Date,
    note: string,
    totalPrice: number,
    id: number
  ) {
    this.growingActivityRecord = growingActivityRecord;
    this.customerId = customerId;
    this.date = date;
    this.note = note;
    this.totalPrice = totalPrice;
    this.id = id < 0 ? 0 : id;
  }
}

class GrowingActivityRecordCreateModel {
  growingActivityRegistryId: number;
  totalAmmount: number;
  customPrice: number;
  constructor(
    growingActivityRegistryId: number,
    totalAmmount: number,
    customPrice: number
  ) {
    this.growingActivityRegistryId = growingActivityRegistryId;
    this.totalAmmount = totalAmmount;
    this.customPrice = customPrice;
  }
}

class GrowingActivityRecordUpdateModel {
  growingActivityRegistryId: number;
  totalAmmount: number;
  customPrice: number;
  id: number;
  constructor(
    growingActivityRegistryId: number,
    totalAmmount: number,
    customPrice: number,
    id: number
  ) {
    this.growingActivityRegistryId = growingActivityRegistryId;
    this.totalAmmount = totalAmmount;
    this.customPrice = customPrice;
    this.id = id;
  }
}

export {
  GrowingActivityCreateModel,
  GrowingActivityUpdateModel,
  GrowingActivityRecordCreateModel,
  GrowingActivityRecordUpdateModel
};
