import axios from 'axios';
import store from '../store/index';
import router from '../router';
const instance = axios.create({});

const cleanGlobalError = () => {
  store.state.globalErrorMessage = null;
  store.state.globalErrorStatus = null;
  store.state.globalErrorStatusText = null;
};

instance.interceptors.request.use(
  function(config) {
    config.baseURL = store.state.API_baseUrl;
    config.headers.Authorization = `Bearer ${store.state.auth.token}`;
    cleanGlobalError();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (Array.isArray(error?.response?.data)) {
      store.state.globalErrorMessage = error.response.data.join('\r\n');
    } else {
      if (
        (error?.response?.data ?? '').includes('FOREIGN KEY constraint failed')
      ) {
        store.state.globalErrorMessage =
          'Nelze smazat záznam, který má pod sebou propojení s jiným záznamem. Nejprve smažte podřazené záznamy.';
      } else {
        store.state.globalErrorMessage = JSON.stringify(
          error?.response?.data ?? ''
        );
      }
    }
    store.state.globalErrorStatus = error?.response?.status;
    store.state.globalErrorStatusText = error?.response?.statusText;

    if (
      error?.response?.status == 401 /*|| error?.message == 'Network Error'*/
    ) {
      store.state.auth.isAuthenticated = false;
      router.push({
        path: '/Account/Login'
      });
    }

    return Promise.reject(error.response);
  }
);

export default instance;
