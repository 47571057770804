<template>
  <div @mouseover="onHover">
    <v-treeview
      ref="treeView"
      v-bind="computedProps"
      v-on="$listeners"
      activatable
      dense
      return-object
      hoverable
      @update:active="onActivated"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-treeview>
    <v-tooltip
      top
      v-model="tooltip"
      :nudge-bottom="10"
      :activator="hoveredItem"
    >
      <span>{{ tooltipValue }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VTreeview } from 'vuetify/lib';
import { getTreeTableData } from '../../utils/TreeNodesHelper';

export default {
  data() {
    return {
      tooltip: false,
      hoveredItem: null,
      tooltipValue: null
    };
  },
  extends: VTreeview,
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    parentId: {
      type: String,
      default: null
    },
    selectedItem: { type: Object }
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.items = this.computedItems;
      return props;
    },
    computedItems() {
      return this.createChildrenProperty(this.items, undefined);
    }
  },
  methods: {
    onHover(event) {
      // zobrazeni tooltipu nad bunkou, kde se do ni nevesel cely obsah
      if (event.target.nodeName === 'TD') {
        const el = event.target;
        const isOverflowing = el.clientWidth < el.scrollWidth;
        this.hoveredItem = null;
        this.tooltip = false;

        if (isOverflowing) {
          this.$nextTick(() => {
            this.hoveredItem = event.target;
            this.tooltip = true;
            this.tooltipValue = event.target.innerText;
          });
        }
      }
    },
    onActivated(array) {
      this.$emit('update:selectedItem', array[0]);
    },
    createChildrenProperty(data, root) {
      const rootObj = {};
      rootObj[this.parentId] = root;
      rootObj[this.itemKey] = root;
      return getTreeTableData(rootObj, data);
    }
  }
};
</script>

<style lang="scss">
.v-treeview-node__label {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
