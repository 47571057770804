var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"top":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-text-field-simplemask',{ref:"inputMask",class:{ requiredField: _vm.jePovinne && !_vm.computedValue },style:(_vm.stylesObject),attrs:{"label":_vm.label,"properties":Object.assign({}, {hideDetails: _vm.componentIsFilter},
          _vm.computedProps,
          {singleLine: _vm.computedProps.disabled && !_vm.computedValue}),"options":{
          inputMask: _vm.inputMask,
          outputMask: _vm.outputMask,
          empty: '',
          applyAfter: false,
          alphanumeric: false,
          lowerCase: false
        }},on:{"blur":_vm.onLostFocus},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})]}}]),model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('span',[_vm._v(_vm._s(_vm.errorMessage))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }