/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';

const state = {
  colorMode: null,
  layoutMode: null,
  rowsInTable: 20
};
const getters = {
  ...make.getters(state),
  getColorMode(state) {
    const savedColorMode = localStorage.getItem('FC_ColorMode');
    state.colorMode = savedColorMode ?? 'light';
    return state.colorMode;
  },
  getLayoutMode(state) {
    const savedLayoutMode = localStorage.getItem('FC_LayoutMode');
    state.layoutMode = savedLayoutMode ?? 'static';
    return state.layoutMode;
  },
  getRowsInTable(state) {
    const saved = localStorage.getItem('FC_RowsInTable');
    if (saved) {
      state.rowsInTable = parseInt(saved, 10);
    }
    return state.rowsInTable;
  }
};
const actions = {
  ...make.actions(state),
  switchColorMode({ commit }) {
    commit('setColorMode');
  },
  switchLayoutMode({ commit }) {
    commit('setLayoutMode');
  },
  setRowsInTable({ commit }, rows) {
    commit('setRowsInTable', rows);
  }
};
const mutations = {
  ...make.mutations(state),
  setColorMode(state) {
    state.colorMode = state.colorMode == 'light' ? 'dark' : 'light';
    localStorage.setItem('FC_ColorMode', state.colorMode);
  },
  setLayoutMode(state) {
    state.layoutMode = state.layoutMode == 'static' ? 'overlay' : 'static';
    localStorage.setItem('FC_LayoutMode', state.layoutMode);
  },
  setRowsInTable(state, rows) {
    state.rowsInTable = rows;
    localStorage.setItem('FC_RowsInTable', state.rowsInTable);
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
