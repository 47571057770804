// globalni mixin ktery obsluhuje zobrazovani loading everlay

import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      route: ''
    };
  },
  computed: {
    loaderVisible: {
      get() {
        return this.$store.state.loadingOverlayVisible;
      },
      set(value) {
        this.$store.state.loadingOverlayVisible = value;
      }
    },
    loaderLabel: {
      get() {
        return this.$store.state.loadingOverlayLabel;
      },
      set(value) {
        this.$store.state.loadingOverlayLabel = value;
      }
    }
  },
  methods: {
    hideLoader() {
      this.loaderVisible = false;
    },
    showLoader(label = '') {
      this.loaderVisible = true;
      this.loaderLabel = label;
    }
  }
});
