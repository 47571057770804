<template>
  <v-form ref="form" v-model="formValid" lazy-validation style="height:100%;">
    <v-container class="d-flex flex-column" style="height:100%;">
      <v-card class="fill-height-accounting">
        <v-stepper v-model="step" class="fill-height-accounting">
          <v-stepper-header class="px-16">
            <v-stepper-step :complete="step > 1" step="1">
              Volba parametrů
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Přehled
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">
              Export
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="fill-height-accounting">
            <v-stepper-content step="1" class="fill-height-accounting">
              <v-container>
                <v-card class="pa-4">
                  <v-row dense>
                    <v-col>
                      <Select
                        label="Společnost"
                        v-model="parameters.companyId"
                        :items="companyList"
                        :item-text="
                          c =>
                            `${c.name} ${c.city &&
                              c.city.length !== 0 &&
                              '(' + c.city.trim() + ')' || ''}`
                        "
                        item-value="id"
                        jePovinne
                        hledani
                        :zvolitPrvni="false"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col 
                      ><Select
                        label="Lokality"
                        v-model="parameters.localities"
                        :items="filteredLocalities"
                        item-text="name"
                        item-value="id"
                        hledani
                        multiple
                        :zvolitPrvni="false"
                    /></v-col>
                  </v-row>
                  <v-row dense
                    ><v-col class="d-flex align-center">
                      <RadioGroup v-model="parameters.selling" class="mr-4">
                        <Button
                          value="1"
                          :color="parameters.selling == 1 ? 'primary' : ''"
                          >Nákup</Button
                        >
                        <Button
                          value="2"
                          :color="parameters.selling == 2 ? 'primary' : ''"
                        >
                          Prodej</Button
                        >
                      </RadioGroup>
                      <Checkbox
                        hide-details
                        label="Započítat pěstební činnost"
                        v-model="parameters.includeGrowingActivity"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense
                    ><v-col>
                      <DatePicker
                        label="Od"
                        v-model="parameters.from"
                        jePovinne
                      /> </v-col
                  ></v-row>
                  <v-row dense
                    ><v-col>
                      <DatePicker
                        label="Do"
                        v-model="parameters.to"
                        jePovinne
                      /> </v-col
                  ></v-row>
                  <!-- <v-row
                    ><v-col>
                      <Select
                        label="Vícenáklady"
                        :items="additionalCostsRegistry"
                        item-text="name"
                        item-value="id"
                        v-model="parameters.additionalCostIds"
                        multiple
                        hledani
                        :zvolitPrvni="false"
                        :menu-props="{ top: true, offsetY: true }"
                      /> </v-col
                  ></v-row> -->
                  <v-row dense
                    ><v-col>
                      <InputNumber
                        label="Zprostředkování prodeje %"
                        v-model="parameters.margin"
                      /> </v-col
                  ></v-row>
                  <v-row dense class="pb-8">
                    <v-col>
                      <div class="d-flex flex-row align-center pb-2">
                        <span class="subtitle mr-4">Výběr vícenákladů</span>
                        <Button
                          @click="addAdditionalCost"
                          :povolenaEditace="
                            !!parameters.companyId && !!parameters.to
                          "
                          ><v-icon>mdi-plus</v-icon></Button
                        >
                        <Button
                          @click="removeAdditionalCost"
                          :povolenaEditace="
                            !!selectedRowAdditionalCostsRegistryLocality
                          "
                          ><v-icon>mdi-delete-outline</v-icon></Button
                        >
                      </div>

                      <DataTable
                        :headers="[
                          { text: 'Lokalita', value: 'locality.name' },
                          {
                            text: 'Vícenáklad',
                            value: 'additionalCostsRegistry.name'
                          }
                        ]"
                        :items="additionalCostsRegistryLocality"
                        :server-items-length="
                          additionalCostsRegistryLocalityLength
                        "
                        item-key="id"
                        :selectedItem.sync="
                          selectedRowAdditionalCostsRegistryLocality
                        "
                        single-select
                        :hide-default-footer="true"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-card-actions> </v-card-actions>
              </v-container>
              <div class="buttons">
                <Button color="primary" @click="nextStep">
                  Pokračovat
                </Button>
                <Button :povolenaEditace="false">
                  Zpět
                </Button>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="fill-height-accounting">
              <span>
                <v-card style="margin:2px;" color="secondary"
                  ><v-card-title
                    class="d-flex justify-space-between"
                    v-html="overviewHeader"
                  ></v-card-title
                ></v-card>
                <v-expansion-panels
                  accordion
                  focusable
                  multiple
                  v-model="panel"
                  style="padding:2px;"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-weight:bold;"
                      >Dodací listy ({{
                        deliveryNotesList.length
                      }})</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <DataTable
                        :headers="deliveryNotesHeaders"
                        :items="deliveryNotesList"
                        item-key="id"
                        :selectedItem.sync="selectedDeliveryNote"
                        @dblclick:row="showDeliveryNoteDialog(formModes.read)"
                        :server-items-length="deliveryNotesList.length"
                        single-select
                        group-by="localitiesGroup"
                        hide-default-footer
                        disable-sort
                      >
                        <template
                          v-slot:[`group.header`]="{
                            group,
                            headers,
                            toggle,
                            isOpen
                          }"
                        >
                          <td :colspan="headers.length">
                            <v-btn
                              @click="toggle"
                              small
                              icon
                              :ref="group"
                              :data-open="isOpen"
                            >
                              <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                              <v-icon v-else>mdi-chevron-down</v-icon>
                            </v-btn>
                            Lokalita: <b>{{ group }}</b>
                          </td>
                        </template>
                        <template v-slot:[`item.detail`]="{ item }">
                          <Button
                            text
                            @click="
                              showDeliveryNoteDialog(formModes.read, item.id)
                            "
                            >Zobrazit detail</Button
                          >
                        </template>
                        <template v-slot:[`item.destination`]="{ item }">
                          {{ item.destination.name }}
                        </template>
                        <template v-slot:[`item.localities`]="{ item }">
                          {{
                            `${item.companyFrom &&
                              item.companyFrom
                                .name} - ${item.deliveryNotesLocalities &&
                              item.deliveryNotesLocalities
                                .map(el => el.locality.name)
                                .join(', ')}`
                          }}
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          {{ item.date ? $moment(item.date).format('L') : '' }}
                        </template>
                        <template v-slot:[`body.append`]="{}">
                          <tr>
                            <td
                              :colspan="7"
                              style="text-align:right;font-weight: bold;"
                            >
                              Celkem množství
                              {{
                                accountingData &&
                                  accountingData.deliveryNotes &&
                                  accountingData.deliveryNotes.volume
                              }}
                              m³
                              <span style="padding-left:2rem;"></span>
                              Celkem cena
                              {{
                                accountingData &&
                                  accountingData.deliveryNotes &&
                                  formatPrice(
                                    accountingData.deliveryNotes.price
                                  )
                              }}

                              <span style="padding-right:2rem;"></span>
                            </td>
                          </tr>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-weight:bold;"
                      >Vícenáklady ({{
                        additionalCostsList.length
                      }})</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <DataTable
                        :headers="headersAdditionalCosts"
                        :items="additionalCostsList"
                        item-key="id"
                        :selectedItem.sync="selectedAdditionalCosts"
                        :server-items-length="additionalCostsList.length"
                        single-select
                        hide-default-footer
                        disable-sort
                      >
                        <template v-slot:[`body.append`]="{}">
                          <tr>
                            <td
                              :colspan="3"
                              style="text-align:right;font-weight: bold;"
                            >
                              Celkem cena
                              {{
                                accountingData &&
                                  accountingData.additionalCosts &&
                                  formatPrice(
                                    accountingData.additionalCosts.totalPrice
                                  )
                              }}

                              <span style="padding-right:2rem;"></span>
                            </td>
                          </tr>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-weight:bold;"
                      >Ostatní náklady ({{
                        otherCostsList.length
                      }})</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <DataTable
                        :headers="headersOtherCosts"
                        :items="otherCostsList"
                        item-key="id"
                        :selectedItem.sync="selectedOtherCosts"
                        :server-items-length="otherCostsList.length"
                        single-select
                        hide-default-footer
                        disable-sort
                      >
                        <template v-slot:[`body.append`]="{}">
                          <tr>
                            <td
                              :colspan="2"
                              style="text-align:right;font-weight: bold;"
                            >
                              Celkem cena
                              {{
                                accountingData &&
                                  accountingData.deliveryNotes &&
                                  formatPrice(
                                    accountingData.deliveryNotes.otherCostsTotal
                                  )
                              }}

                              <span style="padding-right:2rem;"></span>
                            </td>
                          </tr>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-weight:bold;"
                      >Pěstební činnosti ({{
                        growingActivitiesList.length
                      }})</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <DataTable
                        :headers="headersGrowingActivity"
                        :items="growingActivitiesList"
                        item-key="id"
                        :selectedItem.sync="selectedGrowingActivity"
                        :server-items-length="growingActivitiesList.length"
                        single-select
                        hide-default-footer
                        disable-sort
                      >
                        <template v-slot:[`item.date`]="{ item }">
                          {{ item.date ? $moment(item.date).format('L') : '' }}
                        </template>
                        <template v-slot:[`body.append`]="{}">
                          <tr>
                            <td
                              :colspan="2"
                              style="text-align:right;font-weight: bold;"
                            >
                              Celkem cena
                              {{
                                accountingData &&
                                  accountingData.growingActivities &&
                                  formatPrice(
                                    accountingData.growingActivities.totalPrice
                                  )
                              }}

                              <span style="padding-right:2rem;"></span>
                            </td>
                          </tr>
                        </template>
                      </DataTable>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header style="font-weight:bold;"
                      >Doprava</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div
                        class="d-flex flex-row justify-end"
                        style="padding-right:3.5rem;"
                      >
                        <span style="padding-right:2rem;font-weight: bold;">
                          Celkem vzdálenost:
                          {{
                            accountingData &&
                              accountingData.deliveryNotes &&
                              accountingData.deliveryNotes.trafficDistance
                          }}
                          km
                        </span>
                        <span style="font-weight: bold;">
                          Celkem cena:
                          {{
                            accountingData &&
                              accountingData.deliveryNotes &&
                              formatPrice(
                                accountingData.deliveryNotes.trafficPrice
                              )
                          }}
                        </span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </span>
              <div class="buttons" style="margin-top:1rem;">
                <Button color="primary" @click="getPdf">
                  Exportovat
                </Button>
                <Button @click="step = 1">
                  Zpět
                </Button>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3" class="fill-height-accounting">
              <v-card class="mb-12" color="grey lighten-1"></v-card>
              <div class="buttons">
                <Button color="primary" @click="step = 1">
                  Zpět na volbu parametrů
                </Button>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import { saveAs } from 'file-saver';
import {
  AccountingModel,
  AdditionalCostRegistryLocalityModel
} from '../store/models/accountingModels';
import dataTableContextMenuMixin from '../mixins/datatableContextMenuMixin';
import DeliveryNoteDetail from '../components/DeliveryNoteDetail';
import AdditionalCostRegistryLocality from '../components/AdditionalCostRegistryLocality.vue';

export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formValid: true,
      parameters: {},
      accountingData: {},
      step: 1,
      panel: [0],
      selectedDeliveryNote: null,
      deliveryNotesHeaders: [
        { text: 'Číslo', value: 'sequenceNumber', width: '8%' },
        { text: 'Datum', value: 'date', width: '8%' },
        { text: 'Odkud', value: 'localities', width: '20%' },
        { text: 'Komu', value: 'destination', width: '20%' },
        { text: 'Nákupní cena', value: 'totalBuyingPrice', width: '8%' },
        { text: 'Prodejní cena', value: 'totalSellingPrice', width: '8%' },
        { text: '', value: 'detail', width: '15%' }
      ],
      selectedOtherCosts: null,
      headersOtherCosts: [
        { text: 'Název', value: 'name' },
        { text: 'Cena', value: 'price' }
      ],
      selectedAdditionalCosts: null,
      headersAdditionalCosts: [
        { text: 'Název', value: 'additionalCostsRegistry.name' },
        { text: 'Lokalita', value: 'locality.name' },
        { text: 'Cena za m³', value: 'additionalCostsRegistry.unitPrice' }
      ],
      selectedGrowingActivity: null,
      headersGrowingActivity: [
        { text: 'Datum', value: 'date', width: '20%' },
        { text: 'Činnosti', value: 'activities' }
      ],
      accountingModel: null,
      additionalCostsRegistryLocality: [],
      selectedRowAdditionalCostsRegistryLocality: null,
      additionalCostsRegistryLocalityLength: 0
    };
  },
  async mounted() {
    // await this.getAdditionalCostsRegistry({ enablePaging: false });
    // await this.verifySuccessCall();
    await this.getCompanyList();
    await this.verifySuccessCall();

    await this.getLocalities();
    await this.verifySuccessCall();

    await this.getGrowingActivitiesRegistry({
      ...this.filters,
      ...this.options,
      enablePaging: false,
      date: this.filterDate
    });

    this.$set(this.parameters, 'includeGrowingActivity', true);

    this.$watch(
      () => this.parameters.companyId,
      () => {
        this.$set(this.parameters,'localities',this.filteredLocalities?.map(el=>el.id));
      }
    );
  },
  computed: {
    ...window.get([
      'company/companyList',
      'locality/localities',
      'additionalCostsRegistry/additionalCostsRegistry',
      'formModes'
    ]),
    deliveryNotesList() {
      const output =
        this.accountingData?.deliveryNotes?.deliveryNotes?.map(el => ({
          ...el,
          companyFrom: this.companyList.find(
            com => com.id === el.deliveryNotesLocalities[0].locality.companyId
          ),
          localitiesGroup: `${el.deliveryNotesLocalities &&
            el.deliveryNotesLocalities.map(l => l.locality.name).join(', ')}`
        })) ?? [];
      return this.$_.orderBy(output, ['localitiesGroup'], ['asc']);
    },
    otherCostsList() {
      return this.accountingData?.deliveryNotes?.otherCosts ?? [];
    },
    additionalCostsList() {
      return this.accountingData?.additionalCosts?.additionalCosts ?? [];
    },
    growingActivitiesList() {
      return (
        this.accountingData?.growingActivities?.growingActivities?.map(el => ({
          ...el,
          activities: el.growingActivities.map(a =>
            this.getParentName(a.growingActivityRegistry)
          )
        })) ?? []
      );
    },
    overviewHeader() {
      const companyName = this.companyList.find(
        el => el.id == parseInt(this.parameters.companyId, 10)
      )?.name;
      const action =
        this.parameters.selling != 1
          ? '<b>Prodej</b>&nbsp; pro společnost'
          : '<b>Nákup</b>&nbsp; od společnosti';
      const date = `za období od ${this.$moment(this.parameters.from).format(
        'L'
      )} do ${this.$moment(this.parameters.to).format('L')}`;
      const margin =
        !!this.parameters.margin && this.parameters.margin != 0
          ? `,marže ${this.parameters.margin}%`
          : '';
      const left = `${action}&nbsp; <b>${companyName}</b>&nbsp; ${date} ${margin}`;
      const right = `Celkem ${this.formatPrice(
        this.accountingData?.totalPrice ?? 0
      )}`;
      return `<span>${left}</span><span>${right}</span>`;
    },
    filteredLocalities(){
      return this.localities?.filter(el=>el.companyId===this.parameters.companyId)  || [];
    }
  },
  methods: {
    ...window.call([
      'company/getCompanyList',
      'locality/getLocalities',
      'additionalCostsRegistry/getAdditionalCostsRegistry',
      'accounting/getAccountingData',
      'accounting/getAccountingPdf',
      'growingActivityRegistry/getGrowingActivitiesRegistry'
    ]),
    getParentName(growingActivity) {
      let item = growingActivity;
      const parents = [];
      while (item) {
        parents.push(item.name);
        item = item.parent;
      }
      return parents.reverse().join(' > ');
    },
    async addAdditionalCost() {
      const dialogResult = await this.$dialog.showAndWait(
        AdditionalCostRegistryLocality,
        {
          width: '30rem',
          persistent: true,
          formModeProp: this.formModes.new,
          idProp: null,
          companyId: this.parameters.companyId,
          date: this.$moment(this.parameters.to)
            .utc(true)
            .format()
        }
      );
      if (dialogResult.confirmed) {
        this.additionalCostsRegistryLocality.push({
          id:
            (this.$_.maxBy(this.additionalCostsRegistryLocality, 'id')?.id ??
              0) + 1,
          locality:
            dialogResult.newAdditionalCostsRegistryLocality.localityValue,
          additionalCostsRegistry:
            dialogResult.newAdditionalCostsRegistryLocality
              .additionalCostsRegistryValue
        });
        this.additionalCostsRegistryLocalityLength =
          this.additionalCostsRegistryLocalityLength + 1;
      }
    },
    removeAdditionalCost() {
      this.additionalCostsRegistryLocality = this.additionalCostsRegistryLocality.filter(
        el => el.id != this.selectedRowAdditionalCostsRegistryLocality.id
      );
      this.additionalCostsRegistryLocalityLength =
        this.additionalCostsRegistryLocalityLength - 1;
    },
    async showDeliveryNoteDialog(formMode, id) {
      const dialogResult = await this.$dialog.showAndWait(DeliveryNoteDetail, {
        width: '60rem',
        persistent: true,
        formModeProp: formMode,
        idProp:
          formMode === this.formModes.new
            ? null
            : id ?? this.selectedDeliveryNote?.id
      });
      if (dialogResult.confirmed) {
        await this.getData();
      }
    },
    async nextStep() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      this.step++;
      if (this.step == 2) {
        await this.getData();
        this.panel = [0];
        if (this.additionalCostsList.length) {
          this.panel.push(1);
        }
        if (this.otherCostsList.length) {
          this.panel.push(2);
        }
        if (this.growingActivitiesList.length) {
          this.panel.push(3);
        }
        this.panel.push(4);
      }
    },
    async getData() {
      const additionalCosts = this.additionalCostsRegistryLocality.map(
        el =>
          new AdditionalCostRegistryLocalityModel(
            el.locality.id,
            el.additionalCostsRegistry.id
          )
      );
      this.accountingModel = new AccountingModel(
        this.parameters.companyId,
        this.parameters.from,
        this.parameters.to,
        this.parameters.selling === '2',
        this.parameters.includeGrowingActivity,
        this.parameters.margin,
        additionalCosts,
        this.parameters.localities
      );
      const { data } = await this.getAccountingData(this.accountingModel);
      this.accountingData = data;
    },
    async getPdf() {
      const pdfData = await this.getAccountingPdf(this.accountingModel);
      const companyName = this.companyList.find(
        el => el.id == parseInt(this.parameters.companyId, 10)
      )?.name;

      saveAs(
        new Blob([Buffer.from(pdfData.data, 'base64')], {
          type: 'application/pdf'
        }),
        `Vyúčtování - ${companyName} (${this.$moment(
          this.parameters.from
        ).format('L')} - ${this.$moment(this.parameters.to).format('L')}).pdf`
      );
    }
  }
};
</script>

<style lang="scss">
.fill-height-accounting {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.v-stepper__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.buttons {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  & > * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
