class LoginModel {
  username: string;
  password: string;
  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}

interface LoginResponse {
  token: string;
  userId: number;
  roles: Array<string>;
}

class RegisterModel {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  constructor(
    username: string,
    email: string,
    password: string,
    confirmPassword: string
  ) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }
}

class ForgotPasswordModel {
  email: string;
  constructor(email: string) {
    this.email = email;
  }
}

class RequestPasswordResetModel {
  accountEmail: string;
  constructor(accountEmail: string) {
    this.accountEmail = accountEmail;
  }
}

class ResetPasswordModel {
  userEmail: string;
  token: string;
  password: string;
  constructor(userEmail: string, token: string, password: string) {
    this.userEmail = userEmail;
    this.token = token;
    this.password = password;
  }
}

export {
  LoginModel,
  LoginResponse,
  RegisterModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  RequestPasswordResetModel
};
