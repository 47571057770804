import {
  AssortmentCreateModel,
  AssortmentUpdateModel
} from './assortmentModels';
import {
  GrowingActivityCreateModel,
  GrowingActivityUpdateModel
} from './growingActivityModels';
import {
  AdditionalCostsCreateModel,
  AdditionalCostsUpdateModel
} from './additionalCostsModels';
import {
  OtherCostsCreateModel,
  OtherCostsUpdateModel
} from './otherCostsModels';
import { TrafficCreateModel, TrafficUpdateModel } from './trafficModels';
import {
  DeliveryNoteLocalityCreateModel,
  DeliveryNoteLocalityUpdateModel
} from './deliveryNotelocalitiesModels';

class DeliveryNoteCreateModel {
  id: string;
  date: Date;
  locality: Array<DeliveryNoteLocalityCreateModel>;
  deliveryNoteNumber: string;
  intentionNumber: string;
  destinationCompanyId: number;
  totalBuyingPrice: number;
  totalSellingPrice: number;
  assortment: Array<AssortmentCreateModel>;
  growingActivity: Array<GrowingActivityCreateModel>;
  additionalCosts: Array<AdditionalCostsCreateModel>;
  otherCosts: OtherCostsCreateModel;
  note: string;
  traffic: TrafficCreateModel;
  constructor(
    id: string,
    date: Date,
    locality: Array<DeliveryNoteLocalityCreateModel>,
    deliveryNoteNumber: string,
    intentionNumber: string,
    destinationCompanyId: number,
    totalBuyingPrice: number,
    totalSellingPrice: number,
    assortment: Array<AssortmentCreateModel>,
    growingActivity: Array<GrowingActivityCreateModel>,
    additionalCosts: Array<AdditionalCostsCreateModel>,
    otherCosts: OtherCostsCreateModel,
    note: string,
    traffic: TrafficCreateModel
  ) {
    this.id = id;
    this.date = date;
    this.locality = locality;
    this.deliveryNoteNumber = deliveryNoteNumber;
    this.intentionNumber = intentionNumber;
    this.destinationCompanyId = destinationCompanyId;
    this.totalBuyingPrice = totalBuyingPrice;
    this.totalSellingPrice = totalSellingPrice;
    this.assortment = assortment;
    this.growingActivity = growingActivity;
    this.additionalCosts = additionalCosts;
    this.otherCosts = otherCosts;
    this.note = note;
    this.traffic = traffic;
  }
}

class DeliveryNoteUpdateModel {
  id: string;
  deliveryNoteNumber: string;
  intentionNumber: string;
  date: Date;
  locality: Array<DeliveryNoteLocalityUpdateModel>;
  destinationCompanyId: number;
  totalBuyingPrice: number;
  totalSellingPrice: number;
  assortment: Array<AssortmentUpdateModel>;
  growingActivity: Array<GrowingActivityUpdateModel>;
  additionalCosts: Array<AdditionalCostsUpdateModel>;
  otherCosts: OtherCostsUpdateModel;
  note: string;
  traffic: TrafficUpdateModel;
  constructor(
    id: string,
    deliveryNoteNumber: string,
    intentionNumber: string,
    date: Date,
    locality: Array<DeliveryNoteLocalityUpdateModel>,
    destinationCompanyId: number,
    totalBuyingPrice: number,
    totalSellingPrice: number,
    assortment: Array<AssortmentUpdateModel>,
    growingActivity: Array<GrowingActivityUpdateModel>,
    additionalCosts: Array<AdditionalCostsUpdateModel>,
    otherCosts: OtherCostsUpdateModel,
    note: string,
    traffic: TrafficUpdateModel
  ) {
    this.id = id;
    this.deliveryNoteNumber = deliveryNoteNumber;
    this.intentionNumber = intentionNumber;
    this.date = date;
    this.locality = locality;
    this.destinationCompanyId = destinationCompanyId;
    this.totalBuyingPrice = totalBuyingPrice;
    this.totalSellingPrice = totalSellingPrice;
    this.assortment = assortment;
    this.growingActivity = growingActivity;
    this.additionalCosts = additionalCosts;
    this.otherCosts = otherCosts;
    this.note = note;
    this.traffic = traffic;
  }
}

export {
  DeliveryNoteCreateModel,
  DeliveryNoteUpdateModel,
  AssortmentCreateModel,
  AssortmentUpdateModel,
  GrowingActivityCreateModel,
  GrowingActivityUpdateModel,
  AdditionalCostsCreateModel,
  AdditionalCostsUpdateModel,
  OtherCostsCreateModel,
  OtherCostsUpdateModel,
  TrafficCreateModel,
  TrafficUpdateModel,
  DeliveryNoteLocalityCreateModel,
  DeliveryNoteLocalityUpdateModel
};
