/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  dashboardCars: [],
  dashboardDeliveryNotes: [],
  dashboardDeliveryNotesBuySell: [],
  dashboardDeliveryNotesAssortmentSum: []
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getDashboardCars({ commit }) {
    try {
      const { data } = await axios.get('dashboard/get/cars');
      commit('dashboardCars', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getDashboardDeliveryNotes({ commit }) {
    try {
      const { data } = await axios.get('dashboard/get/deliverynotes');
      commit('dashboardDeliveryNotes', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getDashboardDeliveryNotesBuySell({ commit }) {
    try {
      const { data } = await axios.get('dashboard/get/deliverynotesbuysell');
      commit('dashboardDeliveryNotesBuySell', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getDashboardDeliveryNotesAssortmentSum({ commit }) {
    try {
      const { data } = await axios.get(
        'dashboard/get/deliverynotesassortmentsum'
      );
      commit('dashboardDeliveryNotesAssortmentSum', data);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
