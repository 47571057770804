/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  assortmentsRegistry: [],
  assortmentRegistry: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getAssortmentsRegistry({ commit, rootState }, filterOptions) {
    try {
      const { data } = await axios.get('assortmentRegistry/get', {
        params: { date: filterOptions?.date ?? null }
      });
      const filteredData = filterOrderGetPage(data, filterOptions);
      commit('assortmentsRegistry', filteredData);
    } catch (e) {
      commit(
        'assortmentsRegistry',
        rootState.offlineRegistry.assortmentsRegistry
      );
      // error handling is in interceptor
    }
  },
  async getAssortmentRegistryById({ commit }, id) {
    try {
      const { data } = await axios.get(`assortmentRegistry/get/${id}`);
      commit('assortmentRegistry', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createAssortmentRegistry({}, createAssortmentRegistryModel) {
    try {
      await axios.post(
        `assortmentRegistry/create`,
        createAssortmentRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateAssortmentRegistry({}, updateAssortmentRegistryModel) {
    try {
      await axios.put(
        `assortmentRegistry/update`,
        updateAssortmentRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteAssortmentRegistry({}, id) {
    try {
      await axios.delete(`assortmentRegistry/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
