<script>
import { Bar, mixins } from 'vue-chartjs';
import Chartjs from 'chart.js';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: { chartData: { type: Object } },
  data() {
    return {
      dataToRender: null,
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let value = data.datasets[0].data[tooltipItem.index];

              if (value === 0.1) {
                value = 0;
              }

              return value;
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                userCallback(label) {
                  // only show if whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        },
        legend: {
          display: false,
          position: 'right',
          onClick: this.onLegendClick,
          onHover: this.onLegendHover,
          labels: {
            fontColor: '#0388e5',
            generateLabels: function(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                  const meta = chart.getDatasetMeta(0);
                  const ds = data.datasets[0];
                  const arc = meta.data[i];
                  if (!arc) return [];
                  const custom = (arc && arc.custom) || {};
                  const getValueAtIndexOrDefault =
                    Chartjs.helpers.getValueAtIndexOrDefault;
                  const arcOpts = chart.options.elements.arc;
                  const fill = custom.backgroundColor
                    ? custom.backgroundColor
                    : getValueAtIndexOrDefault(
                        ds.backgroundColor,
                        i,
                        arcOpts.backgroundColor
                      );
                  const stroke = custom.borderColor
                    ? custom.borderColor
                    : getValueAtIndexOrDefault(
                        ds.borderColor,
                        i,
                        arcOpts.borderColor
                      );
                  const bw = custom.borderWidth
                    ? custom.borderWidth
                    : getValueAtIndexOrDefault(
                        ds.borderWidth,
                        i,
                        arcOpts.borderWidth
                      );

                  // We get the value of the current label
                  let value =
                    chart.config.data.datasets[arc._datasetIndex].data[
                      arc._index
                    ];
                  if (value === 0.1) {
                    value = 0;
                  }

                  const link = ds.chartLinks && ds.chartLinks[i];

                  return {
                    text: `${label} (${value})`,
                    link: link,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i
                  };
                });
              } else {
                return [];
              }
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        hoverMode: 'index',
        stacked: false
        // onClick: (evt, item) => {
        //   if (item.length !== 0) {
        //     const path =
        //       item[0]._chart.data.datasets[0].chartLinks[item[0]._index];
        //     this.$router.push({ path });
        //   }
        // },
        // onHover: function(e) {
        //   const point = this.getElementAtEvent(e);
        //   if (point.length) e.target.style.cursor = 'pointer';
        //   else e.target.style.cursor = 'default';
        // }
      }
    };
  },
  mounted() {
    this.$el.style.maxHeight = '20rem';
  },
  methods: {
    update() {
      this.validateValues();
      this.renderChart(this.dataToRender, this.options);
    },
    validateValues() {
      let data = this.chartData.datasets[0].data;
      if (this.sumChartValues(data) === 0) {
        data = [];
        data.push(
          ...Object.entries(this.chartData.datasets[0].data).map(() => 0.1)
        );
        this.chartData.datasets[0].data = data;
      }

      this.dataToRender = this.chartData;
    },
    onLegendClick(event, item) {
      const path = item.link;
      this.$router.push({ path });
    },
    onLegendHover(event) {
      this.setLegentPointerStyle(event, 'pointer');
    },
    onNonLegendHover(event) {
      this.setLegentPointerStyle(event, 'default');
    },
    setLegentPointerStyle(event, pointerStyle) {
      event.srcElement.style.cursor = pointerStyle;
    },
    sumChartValues(data) {
      return Object.keys(data).reduce(
        (sum, key) => sum + parseFloat(data[key] || 0),
        0
      );
    }
  }
};
</script>
