<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-col cols="12" class="py-0"
            ><InputText
              label="Název"
              v-model="priceListRegistry.name"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><Checkbox
              label="Je aktivní"
              v-model="priceListRegistry.isActive"
              :povolenaEditace="formMode != formModes.read"
              @change="onDistanceChange(null)"/></v-col
        ></v-row>
      </v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  PriceListRegistryCreateModel,
  PriceListRegistryUpdateModel
} from '../../store/models/priceListRegistryModels';
export default {
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getPriceListRegistryById(this.idProp);
      await this.verifySuccessCall();
    }
    this.original = this.$_.cloneDeep(this.priceListRegistry);
  },
  computed: {
    ...window.get(['formModes']),
    ...window.sync(['priceListRegistry/priceListRegistry']),
    formTitle() {
      return `Ceník dopravného - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'priceListRegistry/getPriceListRegistryById',
      'priceListRegistry/createPriceListRegistry',
      'priceListRegistry/updatePriceListRegistry'
    ]),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.priceListRegistry, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.priceListRegistry = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createPriceListRegistry(
          new PriceListRegistryCreateModel(
            this.priceListRegistry.name,
            this.priceListRegistry.isActive
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Číselník ceníků dopravného bylo vytvořen.');
          await this.close(true);
        }
      } else {
        await this.updatePriceListRegistry(
          new PriceListRegistryUpdateModel(
            this.priceListRegistry.name,
            this.priceListRegistry.id,
            this.priceListRegistry.isActive
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Číselník ceníků dopravného byl upraveno.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
