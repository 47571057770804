/// trafficCharging
/// CHARGE_TO_SELLER = 1,       -- uctovat dopravu prodavajicimu
/// CHARGE_TO_CUSTOMER = 2,     -- uctovat dopravu zakaznikovi
/// NO_CHARGE = 3               -- neuctovat
class TrafficCreateModel {
  distanceKM: number;
  price: number;
  customPrice: boolean;
  carId: number;
  trafficCharging: number;
  assortmentType: number;
  priceListRegistryId: number;
  constructor(
    distanceKM: number,
    price: number,
    customPrice: boolean,
    carId: number,
    trafficCharging: number,
    assortmentType: number,
    priceListRegistryId: number
  ) {
    this.distanceKM = distanceKM;
    this.price = price;
    this.customPrice = customPrice;
    this.carId = carId;
    this.trafficCharging = trafficCharging;
    this.assortmentType = assortmentType;
    this.priceListRegistryId = priceListRegistryId;
  }
}

class TrafficUpdateModel {
  distanceKM: number;
  price: number;
  customPrice: boolean;
  carId: number;
  trafficCharging: number;
  assortmentType: number;
  priceListRegistryId: number;
  id: number;
  constructor(
    distanceKM: number,
    price: number,
    customPrice: boolean,
    carId: number,
    trafficCharging: number,
    assortmentType: number,
    priceListRegistryId: number,
    id: number
  ) {
    this.distanceKM = distanceKM;
    this.price = price;
    this.customPrice = customPrice;
    this.carId = carId;
    this.trafficCharging = trafficCharging;
    this.assortmentType = assortmentType;
    this.priceListRegistryId = priceListRegistryId;
    this.id = id;
  }
}

export { TrafficCreateModel, TrafficUpdateModel };
