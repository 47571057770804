<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-col cols="12" class="py-0"
            ><Select
              label="Typ sortimentu"
              v-model="trafficTariffRegistry.assortmentType"
              :items="assortmentTypes"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Vzdálenost od"
              v-model="trafficTariffRegistry.from"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Vzdálenost do"
              v-model="trafficTariffRegistry.to"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Cena za m³"
              v-model="trafficTariffRegistry.pricePerKM"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Rok"
              v-model="trafficTariffRegistry.year"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <Select
              label="Ceník dopravného"
              v-model="trafficTariffRegistry.priceListRegistry.id"
              :items="priceListsRegistry"
              :povolenaEditace="formMode != formModes.read"
              item-text="name"
              item-value="id"
              jePovinne
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  TrafficTariffRegistryCreateModel,
  TrafficTariffRegistryUpdateModel
} from '../../store/models/trafficTariffRegistryModels';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    await this.getPriceListsRegistry({ all: true });
    await this.verifySuccessCall();
    if (this.idProp) {
      await this.getTrafficTariffRegistryById(this.idProp);
      await this.verifySuccessCall();
    } else {
      this.trafficTariffRegistry.year = new Date().getFullYear();
      this.trafficTariffRegistry.assortmentType = this.assortmentTypes[0].key;
    }
    this.original = this.$_.cloneDeep(this.trafficTariffRegistry);
  },
  computed: {
    ...window.get([
      'formModes',
      'deliveryNote/assortmentTypes',
      'priceListRegistry/priceListsRegistry'
    ]),
    ...window.sync(['trafficTariffRegistry/trafficTariffRegistry']),

    formTitle() {
      return `Ceník dopravného - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'trafficTariffRegistry/getTrafficTariffRegistryById',
      'trafficTariffRegistry/createTrafficTariffRegistry',
      'trafficTariffRegistry/updateTrafficTariffRegistry',
      'priceListRegistry/getPriceListsRegistry'
    ]),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.trafficTariffRegistry, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.trafficTariffRegistry = { priceListRegistry: {} };
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createTrafficTariffRegistry(
          new TrafficTariffRegistryCreateModel(
            this.trafficTariffRegistry.pricePerKM,
            this.trafficTariffRegistry.year,
            this.trafficTariffRegistry.to,
            this.trafficTariffRegistry.from,
            this.trafficTariffRegistry.assortmentType,
            this.trafficTariffRegistry.priceListRegistry.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Záznam byl vytvořen.');
          await this.close(true);
        }
      } else {
        await this.updateTrafficTariffRegistry(
          new TrafficTariffRegistryUpdateModel(
            this.trafficTariffRegistry.pricePerKM,
            this.trafficTariffRegistry.year,
            this.trafficTariffRegistry.to,
            this.trafficTariffRegistry.from,
            this.trafficTariffRegistry.assortmentType,
            this.trafficTariffRegistry.priceListRegistry.id,
            this.trafficTariffRegistry.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Záznam byl upraven.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
