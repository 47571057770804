class UserRolesAddModel {
  userId: string;
  roleId: string;
  constructor(userId: string, roleId: string) {
    this.userId = userId.toString();
    this.roleId = roleId.toString();
  }
}

class UserRolesDeleteModel {
  userId: string;
  roleId: string;
  constructor(userId: string, roleId: string) {
    this.userId = userId.toString();
    this.roleId = roleId.toString();
  }
}

export { UserRolesAddModel, UserRolesDeleteModel };
