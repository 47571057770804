/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import axios from '../plugins/axios';

const state = {};
const getters = {};
const actions = {
  async deleteDeliveryNoteLocality({}, id) {
    try {
      await axios.delete(`deliveryNoteLocality/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = {};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
