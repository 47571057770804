import Vue from 'vue';
import EventBus from '../event-bus';

// globalni mixin, je zde kvuli prechodovym animacim mezi stranky, ktere aby fungovali, musi mit okolo sebe tag<keep-alive>
// kvuli nemu se vsak komponenta jen zneaktivni, ale nevyprazdni pamet. timto se nastavi destroy po deaktivaci
export default Vue.mixin({
  mounted() {
    EventBus.$on('online', async () => {
      await (this.loadData && this.loadData());
    });
  }
});
