var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"},on:{"mouseover":_vm.onHover}},[_c('v-data-table',_vm._g(_vm._b({ref:"datatable",class:{
      'elevation-5': true,
      smallerPaddings: _vm.smallerPaddings,
      correctSelectionCheckBoxPadding: _vm.singleSelect
    },staticStyle:{"height":"inherit"},attrs:{"dense":"","items-per-page":_vm.rowsInTable,"fixed-header":true,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      showCurrentPage: true,
      disablePagination: _vm.blockPaging,
      pageText: _vm.blockPaging
        ? (_vm.serverItemsLength + " z " + _vm.serverItemsLength)
        : '$vuetify.dataFooter.pageText'
    }},on:{"click:row":_vm.selectRow,"contextmenu:row":_vm.selectRow},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.computedProps,false),_vm.$listeners),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2),_c('v-tooltip',{attrs:{"top":"","nudge-bottom":10,"activator":_vm.hoveredCell},model:{value:(_vm.tooltip),callback:function ($$v) {_vm.tooltip=$$v},expression:"tooltip"}},[_c('span',[_vm._v(_vm._s(_vm.tooltipValue))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }