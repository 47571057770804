class GrowingActivityRegistryCreateModel {
  identificator: string;
  name: string;
  unitPrice: number;
  inStock: boolean;
  dateFrom: Date;
  dateTo: Date;
  parentId: number;
  unit: string;
  constructor(
    identificator: string,
    name: string,
    unitPrice: number,
    inStock: boolean,
    dateFrom: Date,
    dateTo: Date,
    parentId: number,
    unit: string
  ) {
    this.identificator = identificator;
    this.name = name;
    this.unitPrice = unitPrice;
    this.inStock = inStock;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.parentId = parentId;
    this.unit = unit;
  }
}

class GrowingActivityRegistryUpdateModel {
  identificator: string;
  name: string;
  unitPrice: number;
  inStock: boolean;
  dateFrom: Date;
  dateTo: Date;
  parentId: number;
  unit: string;
  id: number;
  constructor(
    identificator: string,
    name: string,
    unitPrice: number,
    inStock: boolean,
    dateFrom: Date,
    dateTo: Date,
    parentId: number,
    unit: string,
    id: number
  ) {
    this.identificator = identificator;
    this.name = name;
    this.unitPrice = unitPrice;
    this.inStock = inStock;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.parentId = parentId;
    this.unit = unit;
    this.id = id;
  }
}

export {
  GrowingActivityRegistryCreateModel,
  GrowingActivityRegistryUpdateModel
};
