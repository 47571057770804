<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      hide-overlay
      :temporary="getLayoutMode != 'static'"
    >
      <v-container class="d-flex align-center">
        <img :src="logo" style="width:13.5rem;" class="pr-2 pb-1" />
      </v-container>
      <v-divider></v-divider>
      <v-list>
        <div v-for="(section, i) in items" :key="i">
          <v-subheader>{{ section.section.name }}</v-subheader>
          <v-list-item-group
            :value="sections.find(el => el.id == section.section.id).selected"
            @change="routeChanged(section.section.id, $event)"
          >
            <router-link
              v-for="(route, o) in section.routes"
              :key="o"
              :to="route.to"
              style="text-decoration:none;"
            >
              <v-list-item
                link
                dense
                :disabled="route.to == $router.currentRoute.path"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="route.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="route.label"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" color="error"><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>

    <v-main>
      <v-overlay :value="loadingOverlayVisible" opacity=".2" :z-index="99999">
        <v-card :dark="getColorMode == 'dark'" :light="getColorMode == 'light'">
          <v-card-title>{{ loadingOverlayLabel }}</v-card-title>
          <v-card-text>
            <v-progress-linear
              indeterminate
              color="#385d85"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
      <transition name="component-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      drawer: true,
      items: [],
      activeMenuIndex: 0,
      sections: [
        { id: 1, order: 1, name: 'Hlavní', selected: null },
        { id: 2, order: 2, name: 'Číselníky', selected: null },
        { id: 3, order: 3, name: 'Administrace', selected: null },
        { id: 4, order: 4, name: 'Ostatní', selected: null }
      ]
    };
  },
  computed: {
    ...window.get([
      'userConfig/getLayoutMode',
      'userConfig/getColorMode',
      'loadingOverlayVisible',
      'loadingOverlayLabel',
      'user/userIsInRole',
      'role/appRoles'
    ]),
    logo() {
      return require('../assets/forestcare_nizke.png');
    },
    currentRouteName() {
      return this.$route.meta.title;
    }
  },
  async created() {
    this.createNavigation();
    this.getActiveMenuIndex();
  },
  methods: {
    ...window.call(['auth/logoutUser']),
    createNavigation() {
      const routes = this.$router.options.routes.find(el => el.children)
        .children;
      this.items = routes
        .filter(el => !el.meta.accessible || el.meta.accessible())
        .map(route => ({
          label: route.meta?.title,
          icon: route.meta?.icon,
          to: route.path
        }));

      this.items = this.sections.map(el => ({
        section: el,
        routes: (this.items = routes
          .filter(r => r.meta.section == el.id)
          .filter(el => !el.meta.accessible || el.meta.accessible())
          .map(route => ({
            label: route.meta?.title,
            icon: route.meta?.icon,
            to: route.path
          })))
      }));

      this.items = this.items.filter(el => el.routes.length > 0);

      this.items = this.$_.orderBy(this.items, ['section.order'], ['asc']);
    },
    getActiveMenuIndex() {
      this.items.forEach((el, index) => {
        const routeIndex = el.routes.indexOf(
          el.routes.find(r => r.to == this.$route.path)
        );
        if (index != -1) {
          el.section.selected = routeIndex;
        }
      });

      // return this.items.indexOf(
      //   this.items.find(el => el.to == this.$route.path)
      // );
    },
    async logout() {
      await this.logoutUser();
      this.$router.push({ path: '/Account/Login' });
    },
    routeChanged(section, id) {
      this.sections.forEach(el => {
        if (el.id == section) {
          el.selected = id;
        } else {
          el.selected = null;
        }
      });
    }
  },
  watch: {
    layoutMode(newValue) {
      if (newValue == 'static') {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    }
  }
};
</script>

<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
