<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >Export přehledu dodacích listů
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <DatePicker label="Datum od" v-model="from" jePovinne />
          </v-col>
          <v-col cols="12" class="py-0">
            <DatePicker label="Datum do" v-model="to" jePovinne />
          </v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          :uri="
            `/deliveryNote/get/deliveryNotes?from=${computedFrom}&to=${computedTo}`
          "
          :fileName="
            `Přehled dodacích listů ${this.$moment(from).format(
              'DD.MM.yyyy'
            )} - ${this.$moment(to).format('DD.MM.yyyy')}.csv`
          "
          fileType="csv"
          :povolenaEditace="formValid"
          >Exportovat</Button
        >
        <Button @click="close(false)">Zavřít</Button>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      from: this.$moment()
        .startOf('month')
        .toISOString(),
      to: this.$moment()
        .endOf('month')
        .toISOString()
    };
  },
  methods: {
    async close(confirmed) {
      this.$emit('submit', { confirmed });
    }
  },
  computed: {
    computedFrom() {
      return this.$moment(this.from)
        .utc(true)
        .hour(0)
        .toISOString();
    },
    computedTo() {
      return this.$moment(this.to)
        .utc(true)
        .endOf('day')
        .toISOString();
    }
  }
};
</script>

<style></style>
