class AssortmentCreateModel {
  assortmentRegistryId: number;
  totalPrice: number;
  amount: number;
  customSellingPrice: number;
  customBuyingPrice: number;
  constructor(
    assortmentRegistryId: number,
    totalPrice: number,
    amount: number,
    customSellingPrice: number,
    customBuyingPrice: number
  ) {
    this.assortmentRegistryId = assortmentRegistryId;
    this.totalPrice = totalPrice;
    this.amount = amount;
    this.customSellingPrice = customSellingPrice;
    this.customBuyingPrice = customBuyingPrice;
  }
}

class AssortmentUpdateModel {
  assortmentRegistryId: number;
  totalPrice: number;
  amount: number;
  customSellingPrice: number;
  customBuyingPrice: number;
  id: number;
  constructor(
    assortmentRegistryId: number,
    totalPrice: number,
    amount: number,
    customSellingPrice: number,
    customBuyingPrice: number,
    id: number
  ) {
    this.assortmentRegistryId = assortmentRegistryId;
    this.totalPrice = totalPrice;
    this.amount = amount;
    this.customSellingPrice = customSellingPrice;
    this.customBuyingPrice = customBuyingPrice;
    this.id = id < 0 ? 0 : id;
  }
}

export { AssortmentCreateModel, AssortmentUpdateModel };
