import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

const hasRole = store.getters['user/userIsInRole'];
const roles = store.getters['role/appRoles'];
const isAdmin = () => hasRole(roles.administrator);
Vue.use(VueRouter);

const routes = [
  {
    path: '/Login',
    name: 'Přihlášení',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/ResetPassword',
    name: 'Reset hesla',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/empty',
    component: () => import('../views/_Layout.vue'),
    children: [
      {
        path: '/Overview',
        name: 'Overview',
        meta: {
          title: 'Přehled',
          icon: 'mdi-home',
          section: '1',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Overview.vue')
      },
      {
        path: '/DeliveryNotes',
        name: 'DeliveryNotes',
        meta: {
          title: 'Dodací listy',
          icon: 'mdi-clipboard-list',
          section: '1',
          accessible: () => isAdmin() || hasRole(roles.driver)
        },
        component: () => import('../views/DeliveryNotes.vue')
      },
      {
        path: '/GrowingActivity',
        name: 'GrowingActivity',
        meta: {
          title: 'Pěstební činnost',
          icon: 'mdi-pine-tree',
          section: '1',
          accessible: () => isAdmin() || hasRole(roles.grower)
        },
        component: () => import('../views/GrowingActivity.vue')
      },
      {
        path: '/Accounting',
        name: 'Accounting',
        meta: {
          title: 'Vyúčtování',
          icon: 'mdi-note-multiple-outline',
          section: '1',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Accounting.vue')
      },
      {
        path: '/CompaniesRegistry',
        name: 'CompaniesRegistry',
        meta: {
          title: 'Adresář',
          icon: 'mdi-notebook',
          section: '2',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Registry/CompaniesRegistry.vue')
      },
      {
        path: '/GrowingActivitesRegistry',
        name: 'GrowingActivitesRegistry',
        meta: {
          title: 'Číselník pěstebních činností',
          icon: 'mdi-format-list-numbered',
          section: '2',
          accessible: () => isAdmin() || hasRole(roles.grower)
        },
        component: () =>
          import('../views/Registry/GrowingActivitiesRegistry.vue')
      },
      {
        path: '/AssortmentsRegistry',
        name: 'AssortmentsRegistry',
        meta: {
          title: 'Číselník sortimentu',
          icon: 'mdi-format-list-numbered',
          section: '2',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Registry/AssortmentsRegistry.vue')
      },

      {
        path: '/AdditionalCostsRegistry',
        name: 'AdditionalCostsRegistry',
        meta: {
          title: 'Číselník vícenákladů',
          icon: 'mdi-format-list-numbered',
          section: '2',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Registry/AdditionalCostsRegistry.vue')
      },
      {
        path: '/TrafficTariffRegistry',
        name: '/TrafficTariffRegistry',
        meta: {
          title: 'Ceník dopravného',
          icon: 'mdi-currency-usd',
          section: '2',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Registry/TrafficTariffRegistry.vue')
      },
      {
        path: '/PriceListRegistry',
        name: '/PriceListRegistry',
        meta: {
          title: 'Číselník ceníků dopravného',
          icon: 'mdi-format-list-numbered',
          section: '2',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Registry/PriceListRegistry.vue')
      },
      {
        path: '/Users',
        name: 'Users',
        meta: {
          title: 'Uživatelé',
          icon: 'mdi-account',
          section: '3',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Users.vue')
      },
      {
        path: '/Cars',
        name: 'Cars',
        meta: {
          title: 'Auta',
          icon: 'mdi-car-pickup',
          section: '3',
          accessible: () => isAdmin()
        },
        component: () => import('../views/Cars.vue')
      },
      {
        path: '/Settings',
        name: 'Settings',
        meta: {
          title: 'Nastavení aplikace',
          icon: 'mdi-cog',
          section: '3',
          accessible: () => false
        },
        component: () => import('../views/Settings.vue')
      },
      {
        path: '/UserPreferences',
        name: 'UserPreferences',
        meta: {
          title: 'Preference',
          icon: 'mdi-account-cog',
          section: '4'
        },
        component: () => import('../views/UserPreferences.vue')
      },
      {
        path: '/About',
        name: 'About',
        meta: {
          title: 'O aplikaci',
          icon: 'mdi-information',
          section: '4'
        },
        component: () => import('../views/About.vue')
      }
    ]
  },
  {
    path: '/loginRedirect'
  },
  {
    path: '*',
    redirect: '/loginRedirect'
  }
];

const router = new VueRouter({
  duplicateNavigationPolicy: 'ignore',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const allowedRoutes = ['/LOGIN', '/RESETPASSWORD'];

router.beforeEach((to, from, next) => {
  if (
    !allowedRoutes.includes(to.path.toUpperCase()) &&
    !store.state.auth.isAuthenticated
  ) {
    safeRedirect(from.path, '/Login');
  } else if (to.meta?.accessible && !to.meta?.accessible()) {
    safeRedirect(from.path, '/loginRedirect');
  } else if (to.path === '/loginRedirect') {
    if (hasRole(roles.administrator)) {
      safeRedirect(from.path, '/Overview');
    } else if (hasRole(roles.grower)) {
      safeRedirect(from.path, '/GrowingActivity');
    } else if (hasRole(roles.driver)) {
      safeRedirect(from.path, '/DeliveryNotes');
    }
  } else {
    next();
  }
});

//TODO refresh tokenu nebo odhlaseni
// store.watch(
//   () => store.state.user.sessionExpired,
//   value => {
//     if (store.get('user/sessionExpired') === true) {
//       store.set('user/lastViewBeforeLogout', store.state.app.currentPath);
//       router.go({
//         path: '/login',
//         force: true
//       });
//     }
//   }
// );

const safeRedirect = (from, to) => {
  if (
    from !== to &&
    (!router.currentRoute.path || router.currentRoute.path !== to)
  ) {
    router.push({
      path: to
    });
  }
};

export default router;
