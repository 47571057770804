/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  contacts: [],
  contact: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getContacts({ commit }, options) {
    try {
      const { data } = await axios.get('contact/get');
      commit('contacts', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getContactById({ commit }, id) {
    try {
      const { data } = await axios.get(`contact/get/${id}`);
      commit('contact', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createContact({}, createContactModel) {
    try {
      await axios.post(`contact/create`, createContactModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateContact({}, updateContactModel) {
    try {
      await axios.put(`contact/update`, updateContactModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteContact({}, id) {
    try {
      await axios.delete(`contact/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
