<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="10">
      <template v-slot:activator="{}">
        <v-text-field-simplemask
          ref="inputMask"
          v-model="computedValue"
          v-bind:label="label"
          v-bind:properties="{
            hideDetails: componentIsFilter,
            ...computedProps,
            singleLine: computedProps.disabled && !computedValue
          }"
          v-bind:options="{
            inputMask: inputMask,
            outputMask: outputMask,
            empty: '',
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false
          }"
          :class="{ requiredField: jePovinne && !computedValue }"
          :style="stylesObject"
          @blur="onLostFocus"
        />
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import isInViewport from '../../mixins/isInViewportMixin';
import { VTextField } from 'vuetify/lib';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    stylesObject: {
      type: Object,
      default: null
    },
    mask: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = {};
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace && this.clearable;
      props.prefix = '';
      props.suffix = '';
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-domino-mask'
          : props.prependIcon;

      if (this.jePovinne) {
        props.rules = [value => !!value || 'Pole je povinné'];
      }

      return props;
    },
    computedValue: {
      get() {
        return this.value ?? null;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    inputMask() {
      switch (this.mask) {
        case 'ico':
          return '########';
        case 'psc':
          return '### ##';
        case 'dic':
          return 'CZ##########';
        default:
          return null;
      }
    },
    outputMask() {
      switch (this.mask) {
        case 'ico':
          return '########';
        case 'psc':
          return '### ##';
        case 'dic':
          return '##########';
        default:
          return null;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.inputMask?.$refs?.ref?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.inputMask.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  },
  methods: {
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    },
    onLostFocus() {
      switch (this.mask) {
        case 'ico':
          if (this.computedValue) {
            this.computedValue = this.computedValue.padStart(8, '0');
          }
          break;
        case 'psc':
          if (this.computedValue) {
            this.computedValue = this.computedValue.padStart(5, '0');
          }
          break;
        default:
          break;
      }
    }
  }
};
</script>
