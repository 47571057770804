<template>
  <v-container>
    <v-card class="v-card-movable">
      <v-toolbar dense class="v-toolbar-scrollable">
        <Button
          tooltip="Založení nového záznamu"
          @click="showDialog(formModes.new)"
          ><v-icon>mdi-plus</v-icon></Button
        >
        <Button
          tooltip="Zobrazení detailu záznamu"
          @click="showDialog(formModes.read)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-file-outline</v-icon></Button
        >
        <Button
          tooltip="Změna údajů záznamu"
          @click="showDialog(formModes.edit)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-pencil</v-icon></Button
        >
        <Button
          tooltip="Zrušení (zneaktivnění) záznamu"
          @click="deleteRow"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-delete-outline</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button tooltip="Zobrazí nápovědu" :povolenaEditace="false"
          ><v-icon>mdi-help-circle-outline</v-icon></Button
        >
        <Button tooltip="Zobrazí tiskovou sestavu" :povolenaEditace="false"
          ><v-icon>mdi-printer</v-icon></Button
        >
      </v-toolbar>
      <v-container ref="filtry" class="pt-1 pb-3">
        <v-row dense class="d-flex align-center">
          <v-col md="2" cols="12">
            <InputText
              label="Fulltextové vyhledávání"
              v-model="filters.fulltext"
              clearable
            />
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="d-flex justify-end align-self-end ml-auto"
          >
            <Button @click="loadData" color="primary">Načíst</Button>
          </v-col>
        </v-row>
      </v-container>
      <DataTable
        :headers="headers"
        :items="companies"
        item-key="id"
        :selectedItem.sync="selectedRow"
        @dblclick:row="showDialog(formModes.read)"
        :options.sync="options"
        :server-items-length="totalRecords"
        single-select
        @contextmenu:row="showContextMenu"
      >
        <template v-slot:[`item.DateFrom`]="{ item }">
          {{ item.DateFrom ? $moment(item.DateFrom).format('L LTS') : '' }}
        </template>
        <template v-slot:[`item.DateTo`]="{ item }">
          {{ item.DateTo ? $moment(item.DateTo).format('L LTS') : '' }}
        </template>
      </DataTable>
      <v-menu
        v-model="contextMenuVisible"
        :disabled="!selectedRow"
        :position-x="rightClickPositionX"
        :position-y="rightClickPositiony"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="showDialog(formModes.read)">
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Detail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="showDialog(formModes.edit)">
            <v-list-item-icon> <v-icon>mdi-pencil</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Editace</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteRow">
            <v-list-item-icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zrušit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-actions
        ><SelectFromCisButtons :zvolenyZaznam="selectedRow"
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CompanyDetail from '../../components/Registry/CompanyDetail';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
import selectFromCisButtons from '../../mixins/selectFromCisButtons';

export default {
  mixins: [dataTableContextMenuMixin, selectFromCisButtons],
  data() {
    return {
      rows: [],
      headers: [
        { text: 'Název', value: 'name' },
        { text: 'Město', value: 'city', width: '20%' },
        { text: 'Ulice', value: 'street', width: '20%' },
        { text: 'PSČ', value: 'zipCode', width: '10%' },
        { text: 'IČO', value: 'in', width: '10%' },
        { text: 'DIČ', value: 'tin', width: '10%' }
      ],
      options: {},
      selectedRow: null,
      filters: {},
      totalRecords: 0
    };
  },
  props: {
    isCisModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...window.get(['company/companies', 'formModes'])
  },
  async mounted() {
    await this.loadData();
    this.$refs.filtry.addEventListener('keypress', evt => {
      if (evt.keyCode === 13) {
        this.loadData(); // enter
      }
    });
    this.$watch(
      () => this.options,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    ...window.call(['company/getCompanies', 'company/deleteCompany']),
    async loadData() {
      this.showLoader('Načítání číselníku...');

      await this.getCompanies({
        ...this.filters,
        ...this.options
      });

      this.totalRecords = this.companies.length;
      this.selectedRow = null;
      this.hideLoader();
    },
    async showDialog(formMode) {
      const dialogResult = await this.$dialog.showAndWait(CompanyDetail, {
        width: '40rem',
        persistent: true,
        formModeProp: formMode,
        idProp:
          formMode === this.formModes.new
            ? null
            : parseInt(this.selectedRow.id, 10)
      });
      if (dialogResult.confirmed) {
        this.loadData();
      }
    },
    async deleteRow() {
      if (!(await this.confirmDelete())) {
        return;
      }
      this.showLoader('Probíhá zneaktivnění záznamu...');

      await this.deleteCompany(this.selectedRow.id);

      this.hideLoader();
      if (await this.verifySuccessCall()) {
        this.$dialog.notify.info('Zneaktivnění bylo úspěšné.');
      }
      await this.loadData();
    }
  }
};
</script>
