/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const getDefaultState = () => {
  return {
    trafficTariffsRegistry: [],
    trafficTariffRegistry: { priceListRegistry: {} }
  };
};

const state = getDefaultState();

const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getTrafficTariffRegistry({ commit, rootState }, filterOptions) {
    try {
      let { data } = await axios.get('trafficTariffRegistry/get');
      const assortmentTypes = rootState.deliveryNote.assortmentTypes;

      data = data.map(el => ({
        ...el,
        assortmentTypeName: assortmentTypes.find(
          at => at.key === el.assortmentType
        ).value
      }));
      let filteredData = filterOrderGetPage(data, filterOptions);
      commit('trafficTariffsRegistry', filteredData);
    } catch (e) {
      commit(
        'trafficTariffsRegistry',
        rootState.offlineRegistry.trafficTariffsRegistry
      );
      // error handling is in interceptor
    }
  },
  async getTrafficTariffRegistryById({ commit }, id) {
    try {
      const { data } = await axios.get(`trafficTariffRegistry/get/${id}`);
      commit('trafficTariffRegistry', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createTrafficTariffRegistry({}, createTrafficTariffRegistryModel) {
    try {
      await axios.post(
        `trafficTariffRegistry/create`,
        createTrafficTariffRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateTrafficTariffRegistry({}, updateTrafficTariffRegistryModel) {
    try {
      await axios.put(
        `trafficTariffRegistry/update`,
        updateTrafficTariffRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteTrafficTariffRegistry({}, id) {
    try {
      await axios.delete(`trafficTariffRegistry/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = {
  ...make.mutations(state)
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
