class AssortmentRegistryCreateModel {
  identificator: string;
  name: string;
  unitBuyingPrice: number;
  unitSellingPrice: number;
  dateFrom: Date;
  dateTo: Date;
  constructor(
    identificator: string,
    name: string,
    unitBuyingPrice: number,
    unitSellingPrice: number,
    dateFrom: Date,
    dateTo: Date
  ) {
    this.identificator = identificator;
    this.name = name;
    this.unitBuyingPrice = unitBuyingPrice;
    this.unitSellingPrice = unitSellingPrice;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}

class AssortmentRegistryUpdateModel {
  identificator: string;
  name: string;
  unitBuyingPrice: number;
  unitSellingPrice: number;
  dateFrom: Date;
  dateTo: Date;
  id: number;
  constructor(
    identificator: string,
    name: string,
    unitBuyingPrice: number,
    unitSellingPrice: number,
    dateFrom: Date,
    dateTo: Date,
    id: number
  ) {
    this.identificator = identificator;
    this.name = name;
    this.unitBuyingPrice = unitBuyingPrice;
    this.unitSellingPrice = unitSellingPrice;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.id = id;
  }
}

export { AssortmentRegistryCreateModel, AssortmentRegistryUpdateModel };
