<template>
  <div>
    <v-tooltip top :disabled="!tooltip" open-delay="200">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="{ ...attrs }" v-on="{ ...on }">
          <v-btn
            ref="btn"
            v-bind="{ ...computedProps }"
            v-on="{ ...computedListeners }"
            style="min-width:36px; padding:0 5px;margin-left:2px;margin-right:2px;"
            :style="stylesObject"
            :class="classArray"
          >
            <!-- Pass on all named slots -->
            <slot
              v-for="slot in Object.keys($slots)"
              :name="slot"
              :slot="slot"
            />
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($scopedSlots)"
              :slot="slot"
              slot-scope="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-btn>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib';
import { saveAs } from 'file-saver';
import axios from '../../plugins/axios';

export default {
  extends: VBtn,
  data() {
    return {
      temporaryDisabled: false
    };
  },
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    uri: {
      type: String,
      default: null
    },
    fileName: {
      type: String,
      default: null
    },
    fileType: {
      type: String,
      default: 'pdf'
    },
    tooltip: {
      type: String,
      default: null
    },
    stylesObject: {
      type: Object,
      default: null
    },
    classArray: {
      type: Array,
      default: () => []
    },
    fillWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace || this.temporaryDisabled;
      return props;
    },
    computedListeners() {
      const listeners = { ...this.$listeners };
      if (this.uri) {
        listeners.click = this.download;
      }
      return listeners;
    }
  },
  methods: {
    async download() {
      this.temporaryDisabled = true;
      this.classArray.push('pulseBg');

      const file = await axios.get(this.uri);

      saveAs(
        new Blob([Buffer.from(file.data, 'base64')], {
          type: `application/${this.fileType}`
        }),
        this.fileName
      );

      setTimeout(() => {
        this.$_.remove(this.classArray, function(o) {
          return o === 'pulseBg';
        });

        this.temporaryDisabled = false;
      }, 3000);
    },
    setPadding() {
      // nastaveni vetsi sirky pro tlacitka s textem (ikonova tlacitka maji velikost akorat na ikonu)
      if (
        this.$refs.btn.$el.innerText.trim() !== '' &&
        !this.classArray.includes('px-6')
      ) {
        this.classArray.push('button-text__padding');
      }

      //nastaveni marginu mezi ikonou a textem, pripadne mezi druhou ikonou z druhe strany textu
      if (
        this.$refs.btn.$el.innerText.trim() !== '' &&
        this.$refs.btn.$children.length !== 0
      ) {
        if (
          this.$refs.btn.$children.length >= 1 &&
          !this.classArray.includes('mr-2')
        ) {
          this.classArray.push('button-icon__left');
        }
        if (
          this.$refs.btn.$children.length === 2 &&
          !this.classArray.includes('ml-2')
        ) {
          this.classArray.push('button-icon__right');
        }
      }
    }
  },
  updated() {
    this.setPadding();
  },
  mounted() {
    this.setPadding();
    if (this.fillWidth) {
      this.classArray.push('v-btn--fill-width');
    }
  }
};
</script>
