<template>
  <v-app>
    <form
      id="loginForm"
      @submit.prevent="submitForm"
      class="fill-height"
      autocomplete="on"
    >
      <v-container class="fill-height">
        <v-layout wrap>
          <v-flex
            sm12
            md8
            offset-md2
            lg6
            offset-lg3
            xl4
            offset-xl4
            class="d-flex flex-column justify-center "
          >
            <v-card elevation="4" tag="section">
              <v-card-title class="d-flex">
                <img
                  :alt="platformName"
                  :src="logo"
                  :height="$vuetify.breakpoint.xs ? 40 : 60"
                  class="pr-3"
                />
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mb-2 mt-1">
                <p class="pb-4">
                  Přihlašte se svým uživatelským jménem a heslem:
                </p>
                <v-form ref="formInput" v-model="formValid" lazy-validation>
                  <InputText
                    ref="username"
                    persistent-hint
                    hint="Uživatelské jméno"
                    v-model="username"
                    jePovinne
                    autocomplete="username"
                  />
                  <InputText
                    persistent-hint
                    hint="Heslo"
                    v-model="password"
                    @keydown="passwordKeydown"
                    :type="passwordVisible ? 'text' : 'password'"
                    :append-icon="
                      passwordVisible
                        ? 'mdi-eye-outline'
                        : 'mdi-eye-off-outline'
                    "
                    @click:append="passwordVisible = !passwordVisible"
                    jePovinne
                    autocomplete="current-password"
                  />
                  <span style="color:red;">{{ error }}</span>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
                <v-row
                  ><v-col cols="12" md="6">
                    <Button color="primary" text @click="resetPasswordClick"
                      >Zapomněli jste heslo ?</Button
                    > </v-col
                  ><v-col cols="12" md="6">
                    <Button
                      color="primary"
                      :large="$vuetify.breakpoint.smAndUp"
                      :povolenaEditace="formValid"
                      :stylesObject="{ width: '100%' }"
                      type="submit"
                    >
                      <v-icon left>mdi-lock</v-icon>Přihlásit</Button
                    ></v-col
                  ></v-row
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </form>
  </v-app>
</template>

<script>
/* eslint-disable no-undef */
import {
  LoginModel,
  RequestPasswordResetModel
} from '../store/models/authModels';

export default {
  name: 'Login',
  data() {
    return {
      platformName: 'ForestCare',
      password: null,
      username: null,
      formValid: true,
      passwordVisible: false
    };
  },
  created() {
    this.error = null;
  },
  computed: {
    ...window.get(['auth/isAuthenticated', 'globalErrorStatus']),
    ...window.sync(['auth/error']),
    logo() {
      return require('../assets/forestcare_nizke.png');
    }
  },
  methods: {
    ...window.call([
      'auth/loginUser',
      'auth/requestPasswordReset',
      'offlineRegistry/getData'
    ]),
    async submitForm() {
      await this.login();
    },
    async resetPasswordClick() {
      const email = await this.$dialog.prompt({
        title: 'Zaslání odkazu pro reset hesla',
        text: 'Zadejte svůj email',
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
        },
        actions: [
          {
            text: 'Odeslat',
            key: true
          }
        ]
      });

      if (email) {
        await this.requestPasswordReset(new RequestPasswordResetModel(email));

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info(
            `Email byl úspěšně odeslán na adresu ${email}.`
          );
        }
      }
    },
    async login() {
      if (!this.$refs.formInput.validate()) {
        return;
      }
      await this.loginUser(new LoginModel(this.username, this.password));
      if (this.isAuthenticated) {
        //save data for offline usage
        await this.getData();
        if (await this.verifySuccessCall()) {
          setTimeout(() => {
            this.$dialog.notify.info(
              'Číselníky pro offline režim byly uloženy.'
            );
          }, 1000);
        }

        this.$router.push({ path: '/loginRedirect' });
        // if (window.PasswordCredential) {
        //   const passwordCredential = new PasswordCredential({
        //     id: this.username,
        //     password: this.password
        //   });
        //   navigator.credentials.store(passwordCredential);
        // }
      }
      if (this.globalErrorStatus == 500) {
        await this.verifySuccessCall();
      }
    },
    async passwordKeydown(e) {
      if (e.keyCode == 13) {
        await this.login();
      }
    }
  }
};
</script>
